/* eslint-disable no-unused-vars, array-callback-return, react/no-unescaped-entities */
import { Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { usePlacesWidget } from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import { format } from "date-fns";
import { addWorkerFormJob } from "../../Redux/addJob/actions";
import { getProfile } from "../../Redux/profile/actions";
import { Box } from "@mui/material";

export const GeneralInfoForm = ({
  isOffer,
  setCustomOffer,
  userId,
  users,
  zoom,
  currentUser,
}) => {
  const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyBJWt1Yh6AufjxV8B8Y8UVz_25cYV1fvhs";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getById = useSelector((state) => state.ProfileReducer?.profile);
  // Consolidated state
  const [formState, setFormState] = useState({
    hours: "1",
    days: "1",
    categories: null,
    subCategories: null,
    location: [],
    subCategoryList: [],
    rate: 0,
    longitude: undefined,
    latitude: undefined,
    startDate: new Date(),
    endDate: new Date(),
    postJob: false,
    activeButton: "",
    buttonDisable: false,
  });

  useEffect(() => {
    dispatch(
      getProfile({
        profileId:
          currentUser?.role?.name === "customer" ? userId : currentUser?.id,
      })
    );
  }, [dispatch, userId]);

  console.log(getById, "getByIds");

  useEffect(() => {
    const array =
      getById?.jobs
        ?.filter((item) => !item.isOffer)
        .map((item) => ({
          value: [
            { id: item?.id, title: item?.name, details: item?.description },
          ],
          label: item?.name,
        })) || [];
    setFormState((prev) => ({ ...prev, subCategoryList: array }));
  }, [getById]);

  const CategorySchema = Yup.object().shape({
    description: Yup.string().trim().required("description is required"),
    rate: Yup.number()
      .required("Rate is required")
      .positive("Rate must be a positive number")
      .min(1, "Rate should not be less than 1"),
  });

  const CategoryFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: "",
      rate: 0,
      isPost: "",
      startDate: "",
      endDate: null,
      location: "",
      latitude: "",
      days: "",
      hours: "",
      jobImg: "",
      providers: "",
      remember: true,
      subCategory: "",
    },
    validationSchema: CategorySchema,
    onSubmit: async (values) => {
      if (
        formState.location === "" ||
        !formState.location ||
        !formState.subCategories
      ) {
        return;
      }
      setFormState((prev) => ({
        ...prev,
        postJob: true,
        buttonDisable: true,
      }));
      const formattedStartDate = `"${format(
        formState.startDate,
        "yyyy-MM-dd'T'HH:mm:ssxxx"
      )}"`;
      const formattedEndDate = `"${format(
        formState.endDate,
        "yyyy-MM-dd'T'HH:mm:ssxxx"
      )}"`;
      let nestedArray = formState.location ? [formState.location] : [];
      let flattenedArray = nestedArray.flat(Infinity);
      let data = {
        isOffer: isOffer,
        customerId: userId,
        setCustomOffer: setCustomOffer,
        description: values.description,
        rate: values.rate,
        unit: values.unit,
        category: formState.subCategories.value[0].id,
        days: formState.days,
        hours: formState.hours,
        location: flattenedArray,
        latitude: formState.latitude,
        longitude: formState.longitude,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        zoom: zoom,
        users: users,
        currentUser: currentUser,
        history: navigate,
        setPostJob: setFormState,
        setButtonDisabled: setFormState,
      };
      // setCustomOffer(false);
      dispatch(addWorkerFormJob(data, currentUser, users, zoom));
    },
  });

  const handleInputChange = (e) => {
    setFormState((prev) => ({ ...prev, location: e.target.value }));
  };

  const { ref } = usePlacesWidget({
    apiKey: YOUR_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      setFormState((prev) => ({
        ...prev,
        location: place.formatted_address,
        longitude: place.geometry.location.lng(),
        latitude: place.geometry.location.lat(),
      }));
    },
    options: { types: ["(regions)"] },
    defaultValue: formState.location,
  });

  const PreventFirstZero = (e) => {
    if (e.target?.value?.length === 0 && e.which === "0".charCodeAt(0)) {
      e.preventDefault();
      return false;
    }
  };
  return (
    <>
      <div className="p-1">
        <Form
          onSubmit={CategoryFormik.handleSubmit}
          encType="multipart/form-data"
        >
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Form.Group id="description" className="mb-2">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter job description"
                  name="description"
                  value={CategoryFormik.values.description}
                  onChange={CategoryFormik.handleChange}
                  isInvalid={
                    CategoryFormik.touched.description &&
                    !!CategoryFormik.errors.description
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {CategoryFormik.errors.description}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <Form.Group id="subCategory" className="mb-2">
                <Form.Label>Select GIG</Form.Label>
                <Select
                  name="subCategories"
                  options={formState.subCategoryList}
                  onChange={(value) =>
                    setFormState((prev) => ({ ...prev, subCategories: value }))
                  }
                  defaultValue={formState.subCategories}
                  value={formState.subCategories}
                  isInvalid={
                    CategoryFormik.touched.subCategory &&
                    !!CategoryFormik.errors.subCategory
                  }
                />
                {CategoryFormik.touched.subCategory &&
                  !formState.subCategories && (
                    <div style={{ color: "red" }}>Please Select the GIG</div>
                  )}
              </Form.Group>
            </Col>
            <Col md={12} lg={12} xl={12} className="mb-2">
              <Form.Label>Location</Form.Label>
              <Form.Control
                value={formState.location}
                ref={ref}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
              {CategoryFormik.touched.location &&
                formState?.location?.length === 0 && (
                  <div style={{ color: "red" }}>Location is required</div>
                )}
            </Col>
            <Col md={12} lg={12} xl={12}>
              <Form.Group id="rate" className="mb-2">
                <Form.Label>Rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter rate"
                  name="rate"
                  value={CategoryFormik.values.rate}
                  onChange={CategoryFormik.handleChange}
                  onKeyPress={PreventFirstZero}
                  isInvalid={
                    CategoryFormik.touched.rate && !!CategoryFormik.errors.rate
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {CategoryFormik.errors.rate}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={12} lg={12} xl={12} className="mb-2">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={
                  formState?.startDate &&
                  format(formState?.startDate, "yyyy-MM-dd")
                } // Format the date for input
                onChange={(e) => {
                  setFormState((prev) => ({
                    ...prev,
                    startDate: new Date(e.target.value), // Update state with new date
                  }));
                }}
                isInvalid={
                  CategoryFormik.touched.startDate && !formState.startDate
                }
              />
              {CategoryFormik.touched.startDate && !formState.startDate && (
                <div style={{ color: "red" }}>Start Date is required</div>
              )}
            </Col>
            <Col md={12} lg={12} xl={12} className="mb-2">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                value={
                  formState.endDate && format(formState.endDate, "yyyy-MM-dd")
                } // Format the date for input
                onChange={(e) => {
                  setFormState((prev) => ({
                    ...prev,
                    endDate: new Date(e.target.value), // Update state with new date
                  }));
                }}
                isInvalid={CategoryFormik.touched.endDate && !formState.endDate}
              />
              {CategoryFormik.touched.endDate && !formState.endDate && (
                <div style={{ color: "red" }}>End Date is required</div>
              )}
            </Col>
          </Row>
          <Box display={"flex"} justifyContent={"center"}>
            <Button
              variant="primary"
              type="submit"
              disabled={formState.buttonDisable}
              onClick={() =>
                setFormState((prev) => ({
                  ...prev,
                  activeButton: "Post",
                }))
              }
            >
              {formState.buttonDisable ? "Loading..." : "Post"}
            </Button>
          </Box>
        </Form>
      </div>
    </>
  );
};
