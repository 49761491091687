export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_CUSTOMER_PROFILE = "UPDATE_CUSTOMER_PROFILE";
export const UPDATE_WORKER_PROFILE = "UPDATE_WORKER_PROFILE";
export const UPDATE_COMPLETE_WORKER_PROFILE = "UPDATE_COMPLETE_WORKER_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const BLOCK_USER = "BLOCK_USER";
export const BLOCK_USER_SUCCESS = "BLOCK_USER_SUCCESS";
export const UNBLOCK_USER = "UNBLOCK_USER";
export const UNBLOCK_USER_SUCCESS = "UNBLOCK_USER_SUCCESS";
export const REPORT_USER_LIST = "REPORT_USER_LIST";
export const REPORT_USER_LIST_SUCCESS = "REPORT_USER_LIST_SUCCESS";
export const REPORTED_USER = "REPORTED_USER";
export const REPORTED_USER_SUCCESS = "REPORTED_USER_SUCCESS";
export const HIRED_APPLICANTS = "HIRED_APPLICANTS";
export const HIRED_APPLICANTS_SUCCESS = "HIRED_APPLICANTS_SUCCESS";
export const GET_REVIEWS = "GET_REVIEWS";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";