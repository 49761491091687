import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { getProfile, updateWorkerProfile } from "../../Redux/profile/actions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Row,
} from "@themesberg/react-bootstrap";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete from "react-google-autocomplete";
import { getCategoryList } from "../../Redux/Category/actions";

const WorkerForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyBJWt1Yh6AufjxV8B8Y8UVz_25cYV1fvhs";
  const login = useSelector((state) => state.auth.Auther);
  const getById = useSelector((state) => state.ProfileReducer.profile);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedFrontID, setSelectedFrontID] = useState();
  const [selectedBackID, setSelectedBackID] = useState();
  const [location, setLocation] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [longitude, setLogintude] = useState();
  const [latitude, setLatitude] = useState();
  const [selectedProfileType, setSelectedProfileType] = useState("full-time");
  const [dateofBirth] = React.useState(new Date());
  const [videoURL, setVideoURL] = useState("");
  const handleChange = (selected) => {
    setSelectedProfileType(selected.value);
  };

  const options = [
    { value: "full-time", label: "Full Time" },
    { value: "part-time", label: "Part Time" },
  ];

  const [setLoader] = useState(true);
  const [locationKey, setLocationKey] = useState(false);
  const [imageKey, setImageKey] = useState(false);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    dateOfBirth: Yup.string().required("Date Of Birth is required"),
    phoneNumber: Yup.string().test(
      "isValidPhoneNumber",
      "Invalid phone number",
      (value) => value && isValidPhoneNumber(value)
    ),
    postalCode: Yup.string().required("Postal Code is required"),
    city: Yup.string().required("City is required"),
    province: Yup.string().required("Province is required"),
    transportationAvailable: Yup.string().required(
      "Transportation is required"
    ),
    backID: Yup.string().required("Back ID is required"),
    frontID: Yup.string().required("Front ID is required"),
    selectedParentCategories: Yup.array()
      .min(1, "Please select at least one parent category")
      .required("Parent category is required"),
    selectedSubCategories: Yup.array()
      .min(1, "Please select at least one sub category")
      .required("Sub category is required"),
    // videoURL: Yup.string().url("Invalid URL").required("Video URL is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, reset, control, formState, setValue } =
    useForm(formOptions);

  const { errors } = formState;
  const [user, setUser] = useState();

  useEffect(() => {
    reset(user);
  }, [user, reset]);

  useEffect(() => {
    setUser({
      fullName: getById?.fullName,
      address: getById?.address,
      dateofBirth: getById?.dateofBirth,
      phoneNumber: getById?.phoneNumber,
      city: getById?.city,
      postalCode: getById?.postalCode,
      profileImg: getById?.profileImg,
      videoURL: getById?.videoURL,
    });
  }, [getById]);

  useEffect(() => {
    dispatch(
      getProfile({
        profileId: login?.id,
        setLoader: setLoader,
      })
    );
  }, [dispatch, login?.id, setLoader]);

  //** CODE: Category flow **/
  useEffect(() => {
    dispatch(getCategoryList({ search: "" }));
  }, [dispatch]);

  const CategoryData = useSelector((state) => state?.Category?.getCategoryList);
  const [selectedParentCategories, setSelectedParentCategories] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [categorySubcategoryMapping, setCategorySubcategoryMapping] = useState(
    {}
  );
  const [dataset, setDataset] = useState([]);
  const [frontIdKey, setFrontIdKey] = useState(false);
  const [backIdKey, setBackIdKey] = useState(false);

  useEffect(() => {
    const newSubCategories = [];
    selectedParentCategories.forEach((parent) => {
      const parentCategory = CategoryData.find(
        (cat) => cat.id === parent.value
      );
      if (parentCategory && parentCategory.sub_Categories) {
        newSubCategories.push(...parentCategory.sub_Categories);
      }
    });
    setSubCategoryOptions(
      newSubCategories.map((subCategory) => ({
        value: subCategory.id,
        label: subCategory.title,
      }))
    );
  }, [selectedParentCategories, CategoryData]);

  useEffect(() => {
    const newDataset = Object.keys(categorySubcategoryMapping).map(
      (parentId) => ({
        mainCategoryId: parentId,
        subCategories: categorySubcategoryMapping[parentId],
      })
    );
    setDataset(newDataset);
  }, [categorySubcategoryMapping]);

  const handleParentCategoryChange = (selectedOptions) => {
    setSelectedParentCategories(selectedOptions || []);
    setCategorySubcategoryMapping((prevMapping) => {
      const newMapping = {};
      (selectedOptions || []).forEach((option) => {
        if (prevMapping[option.value]) {
          newMapping[option.value] = prevMapping[option.value];
        } else {
          newMapping[option.value] = [];
        }
      });
      return newMapping;
    });
  };

  const handleSubCategoryChange = (selectedOptions) => {
    setCategorySubcategoryMapping((prevMapping) => {
      const newMapping = { ...prevMapping };
      selectedParentCategories.forEach((parent) => {
        newMapping[parent.value] = selectedOptions
          ? selectedOptions
              .filter((option) => {
                const parentCategory = CategoryData.find(
                  (cat) => cat.id === parent.value
                );
                return parentCategory.sub_Categories.some(
                  (subCat) => subCat.id === option.value
                );
              })
              .map((option) => option.value)
          : [];
      });
      return newMapping;
    });
  };

  const categoryOptions = CategoryData.map((category) => ({
    value: category.id,
    label: category.title,
  }));
  //**** CODE: Category flow ****/

  const languageOptions = [
    { value: "english", label: "English" },
    { value: "spanish", label: "Spanish" },
    { value: "french", label: "French" },
    { value: "german", label: "German" },
  ];

  function onSubmit(data) {
    if (data.phoneNumber && !isValidPhoneNumber(data.phoneNumber)) {
      return;
    } else if (
      !selectedImage ||
      !selectedFrontID ||
      !selectedBackID ||
      location.length === 0
    ) {
      setImageKey(!selectedImage ? true : false);
      setFrontIdKey(!selectedFrontID ? true : false);
      setBackIdKey(!selectedBackID ? true : false);
      setLocationKey(location.length === 0 ? true : false);
      return;
    } else {
      dispatch(
        updateWorkerProfile({
          fullName: data.fullName,
          address: location,
          latitude: latitude,
          longitude: longitude,
          dateofBirth: dateofBirth
            ? moment.utc(dateofBirth).format().toString()
            : getById?.dateofBirth.toString(),
          phoneNumber: data.phoneNumber
            ? data.phoneNumber
            : getById?.phoneNumber,
          city: data.city,
          postalCode: data.postalCode,
          province: data.province,
          languages: selectedLanguages,
          transportationAvailable: data.transportationAvailable,
          id: getById.id,
          profileType: selectedProfileType
            ? selectedProfileType
            : getById?.profileType,
          profileImg: selectedImage ? selectedImage : getById?.profileImg,
          frontID: selectedFrontID ? selectedFrontID : getById?.frontID,
          backID: selectedBackID ? selectedBackID : getById?.backID,
          accountType: "worker",
          history: navigate,
          setLoader: setLoader,
          category: dataset,
          videoURL: videoURL,
        })
      );
    }
  }
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const frontIDChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFrontID(e.target.files[0]);
      setFrontIdKey(false);
    }
  };

  const backIDChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedBackID(e.target.files[0]);
      setBackIdKey(false);
    }
  };

  const inputEl = useRef(null);

  const onButtonClick = () => {
    inputEl.current.click();
  };
  const videoUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setVideoURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    if (getById?.phoneNumber !== undefined) {
      setValue("phoneNumber", getById?.phoneNumber);
    }
    if (selectedFrontID) {
      setFrontIdKey(false);
    }
    if (selectedBackID) {
      setBackIdKey(false);
    }
    if (location) {
      setLocationKey(false);
    }
    if (selectedImage) {
      setImageKey(false);
    }
  }, [
    getById?.phoneNumber,
    setValue,
    selectedFrontID,
    selectedBackID,
    location,
    selectedImage,
  ]);

  return (
    <>
      {user && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12}>
                  <Card
                    border="light"
                    className="text-center p-0 mb-4 profileView"
                    style={{ cursor: "pointer", height: "300px" }}
                  >
                    {selectedImage ? (
                      <Card.Img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Neil Portrait"
                        onClick={onButtonClick}
                        ref={inputEl}
                        className="user-avatar large-avatar rounded-circle mx-auto mt-5"
                      />
                    ) : (
                      <Card.Img
                        src={getById?.profileImg}
                        alt="Neil Portrait"
                        onClick={onButtonClick}
                        ref={inputEl}
                        className="user-avatar large-avatar rounded-circle mx-auto mt-5"
                      />
                    )}
                    <Form.Group className="col my-2">
                      <Form.Control
                        accept="image/*"
                        type="file"
                        id="file"
                        name="file"
                        onChange={imageChange}
                        className="d-none"
                        ref={inputEl}
                        style={{ cursor: "pointer" }}
                      />
                      {imageKey && (
                        <div className="invalid-feedback d-block">
                          Please select a profile image
                        </div>
                      )}
                    </Form.Group>
                    <Card.Body className="pb-5">
                      <Dropdown.Menu className="custom_menu">
                        <Dropdown.Item onClick>
                          <FontAwesomeIcon icon={faEdit} className="me-2" />{" "}
                          Edit
                        </Dropdown.Item>
                      </Dropdown.Menu>
                      <Card.Subtitle
                        className="text-gray mb-2"
                        onClick={onButtonClick}
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Profile image
                      </Card.Subtitle>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Card
                    border="light"
                    className="text-center p-0 mb-4 profileView"
                    style={{ cursor: "pointer", height: "300px" }}
                  >
                    <Form.Group className="col my-2">
                      <Form.Label>Video File</Form.Label>
                      <Form.Control
                        name="videoUrl"
                        type="file"
                        onChange={videoUpload}
                      />
                    </Form.Group>
                    {videoURL && (
                      <video width="100%" height="200" controls>
                        <source src={videoURL} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={12} xl={8}>
              <Card
                border="light"
                className="text-left p-0 mb-4 profileView info"
              >
                <Card.Body className="pb-3">
                  <Card.Title className="text-primary">
                    Basic Information
                  </Card.Title>

                  <Form.Group className="col my-2">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      name="fullName"
                      type="text"
                      {...register("fullName")}
                      className={`form-control ${
                        errors.fullName ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.fullName?.message}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Col lg={12} md={6} className="mb-1">
                      <Form.Label>Profile Type</Form.Label>
                      <Select
                        value={options.find(
                          (option) => option.value === selectedProfileType
                        )}
                        onChange={handleChange}
                        options={options}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Date Of Birth</Form.Label>
                    <Form.Control
                      name="dateOfBirth"
                      type="date"
                      {...register("dateOfBirth")}
                      className={`form-control ${
                        errors.dateOfBirth ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.dateOfBirth?.message}
                    </div>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Phone</Form.Label>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      render={({ field }) => (
                        <PhoneInput
                          {...field}
                          error={
                            field.value
                              ? isValidPhoneNumber(field.value)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                        />
                      )}
                    />
                    <div className="invalid-phone">
                      {errors.phoneNumber && errors.phoneNumber.message}
                    </div>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Address</Form.Label>
                    <Autocomplete
                      apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                      style={{ width: "100%" }}
                      className="form-control pac-target-input"
                      onPlaceSelected={(place) => {
                        setLocation(place.formatted_address);
                        setLogintude(place.geometry.location.lng());
                        setLatitude(place.geometry.location.lat());
                      }}
                    />
                    {locationKey && (
                      <div className="invalid-feedback d-block">
                        Please select a location
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                      name="postalCode"
                      type="text"
                      {...register("postalCode")}
                      className={`form-control ${
                        errors.postalCode ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.postalCode?.message}
                    </div>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      name="city"
                      type="text"
                      {...register("city")}
                      className={`form-control ${
                        errors.city ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.city?.message}
                    </div>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Province</Form.Label>
                    <Form.Control
                      name="province"
                      type="text"
                      {...register("province")}
                      className={`form-control ${
                        errors.province ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.province?.message}
                    </div>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Languages</Form.Label>
                    <Controller
                      name="languages"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isMulti
                          options={languageOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(selectedOptions) => {
                            setSelectedLanguages(
                              selectedOptions.map((option) => option.value)
                            ); // Save selected values in state
                          }}
                          value={selectedLanguages.map((value) =>
                            languageOptions.find(
                              (option) => option.value === value
                            )
                          )} // Set selected values
                        />
                      )}
                    />
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Transportation Available</Form.Label>
                    <Form.Control
                      name="transportationAvailable"
                      type="text"
                      {...register("transportationAvailable")}
                      className={`form-control ${
                        errors.transportationAvailable ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.transportationAvailable?.message}
                    </div>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Front ID</Form.Label>
                    <Form.Control
                      name="frontID"
                      type="file"
                      {...register("frontID")}
                      onChange={frontIDChange}
                      className={`form-control ${
                        errors.frontID ? "is-invalid" : ""
                      }`}
                    />
                    {frontIdKey && (
                      <div className="invalid-feedback d-block">
                        Please select a front ID
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Back ID</Form.Label>
                    <Form.Control
                      name="backID"
                      type="file"
                      {...register("backID")}
                      onChange={backIDChange}
                      className={`form-control ${
                        errors.backID ? "is-invalid" : ""
                      }`}
                    />
                    {backIdKey && (
                      <div className="invalid-feedback d-block">
                        Please select a back ID
                      </div>
                    )}
                  </Form.Group>

                  {/** CODE: Category flow **/}
                  <Form.Group className="col my-2">
                    <Form.Label>Categories</Form.Label>
                    <Controller
                      name="selectedParentCategories"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isMulti
                          options={categoryOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(selectedOptions) => {
                            field.onChange(selectedOptions);
                            handleParentCategoryChange(selectedOptions);
                          }}
                          value={selectedParentCategories}
                        />
                      )}
                    />
                    <div className="invalid-feedback d-block">
                      {errors.selectedParentCategories?.message}
                    </div>
                  </Form.Group>

                  <Form.Group className="col my-2">
                    <Form.Label>Sub Categories</Form.Label>
                    <Controller
                      name="selectedSubCategories"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isMulti
                          options={subCategoryOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(selectedOptions) => {
                            field.onChange(selectedOptions);
                            handleSubCategoryChange(selectedOptions);
                          }}
                          value={selectedParentCategories.flatMap((parent) =>
                            categorySubcategoryMapping[parent.value]?.map(
                              (subId) => {
                                const subCat = subCategoryOptions.find(
                                  (option) => option.value === subId
                                );
                                return subCat || [];
                              }
                            )
                          )}
                          isDisabled={subCategoryOptions.length === 0}
                        />
                      )}
                    />
                    <div className="invalid-feedback d-block">
                      {errors.selectedSubCategories?.message}
                    </div>
                  </Form.Group>
                  {/**** CODE: Category flow ****/}

                  <Form.Group>
                    <div className="d-grid gap-2 col-4 text-center mt-3 mx-auto">
                      <Button
                        variant="primary"
                        color="dark"
                        size="sm"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default WorkerForm;
