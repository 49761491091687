import { Button, Modal } from "@themesberg/react-bootstrap";
import { GeneralInfoForm } from "./form";

const ApplyModal = ({
  setCustomOffer,
  customOffer,
  userId,
  zoom,
  users,
  currentUser,
}) => {
  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={customOffer}
      onHide={() => {
        setCustomOffer(false);
      }}
    >
      <Modal.Header>
        <Modal.Title className="h5">{"Custom Offer"}</Modal.Title>
        <Button
          variant="close"
          aria-label="Close"
          onClick={() => {
            setCustomOffer(false);
          }}
        />
      </Modal.Header>
      <Modal.Body className="">
        <GeneralInfoForm
          isOffer={true}
          setCustomOffer={setCustomOffer}
          userId={userId}
          currentUser={currentUser}
          users={users}
          zoom={zoom}
        />
      </Modal.Body>
    </Modal>
  );
};
export default ApplyModal;
