import React from "react";
import {createRoot } from "react-dom";
import { Elements } from "@stripe/react-stripe-js"; // Import Elements
import { loadStripe } from "@stripe/stripe-js"; // Import loadStripe
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-datetime/css/react-datetime.css";
import "./scss/volt.scss";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./Store";
import App from "./App";
import { GoogleOAuthProvider } from '@react-oauth/google';

// Load Stripe
const stripePromise = loadStripe(
  "pk_test_51LamT7CQo8XqKBkeWbqXJOa27a9Zgz123u3YYbL2vsPuHT3Fmss9OUXywVcRCSWw47HDk4UuzgpPifXT7EJ6T0aq00bFlSGGV0"
);

const root = createRoot(document.getElementById("root"));

// Render the app
root.render(
  <GoogleOAuthProvider clientId="152202356320-bni7srm4s195ltqvnfh6fh6l37docqnk.apps.googleusercontent.com">
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ToastContainer autoClose={4000} pauseOnHover={false} pauseOnFocusLoss />
            <App />
        </PersistGate>
      </Provider>
    </Elements>
  </GoogleOAuthProvider>
);
