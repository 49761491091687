/* eslint-disable */
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Col,
  Image,
  Nav,
  Pagination,
  Row,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import NoRecordFound from "../../components/NoRecordFound";
import Spinner from "../../components/spinner";
import { getRejectedApplicants } from "../../Redux/addJob/actions";
const RejectedApplicants = ({ id }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const params = useLocation();

  let jobId = params.pathname.split("/")[2];
  const [page, setPage] = useState(1);
  const [limit] = useState("5");
  const Applicants = useSelector(
    (state) => state?.addJob?.rejectedApplicants?.data?.applicants
  );
  const Pageination = useSelector(
    (state) => state?.addJob?.rejectedApplicants?.data
  );
  useEffect(() => {
    if (id === "Rejected") {
      dispatch(
        getRejectedApplicants({
          id: jobId,
          page: page,
          limit: limit,
          setLoader: setLoader,
        })
      );
    }
  }, [page, limit, id]);

  const nextPage = () => {
    if (page < Pageination?.pages) {
      setPage(page + 1);
    }
  };
  const previousPage = () => {
    if (1 < page) {
      setPage(page - 1);
    }
  };
  const paginationItems = () => {
    let items = [];
    for (let number = 1; number <= Pageination?.pages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === page}
          onClick={() => {
            setPage(number);
          }}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };
  return (
    <>
      <div className="mx-5">
        <Row className="py-2 justify-content-between">
          {loader ? (
            <Spinner />
          ) : (
            <>
              {Applicants?.length > 0 ? (
                <>
                  <Col lg={6} md={12} sm={12} xs={12} className="pb-3">
                    {Applicants?.map((item, index) => {
                      console.log(item, "profile_rating");
                      return (
                        <>
                          <Card
                            key={index}
                            border="light"
                            className="shadow-sm cardShadow"
                            style={{ marginTop: "15px" }}
                          >
                            <div className="applicantCard">
                              <Image
                                src={item?.users ? item?.users?.profileImg : ""}
                                className="navbar-brand-light"
                              />
                              <div className="detailSection">
                                <span className="left">
                                  <h3 className="mb-1 mt-2">
                                    {item?.users ? item?.users?.fullName : ""}{" "}
                                  </h3>
                                  <Rating
                                    readonly={true}
                                    allowHover={false}
                                    size={25}
                                    initialValue={
                                      item?.users?.profile_rating
                                        ? item?.users?.profile_rating * 20
                                        : ""
                                    } /* Available Props */
                                  />
                                  {/* <span>
                                      {item?.users?.profile_rating ? item?.users?.profile_rating : ""}
                                    </span> */}
                                  <p className="mt-2">
                                    Jobs Completed: <span>25</span>{" "}
                                  </p>
                                  <p className="mt-2">
                                    {!item?.isAccepted &&
                                      "Applicantion is Rejected"}
                                  </p>
                                </span>
                              </div>
                            </div>
                          </Card>
                        </>
                      );
                    })}
                  </Col>
                </>
              ) : (
                <NoRecordFound />
              )}
            </>
          )}

          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <Pagination size={"sm"} className="mb-2 mb-lg-0">
                <Pagination.Prev onClick={() => previousPage()}>
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </Pagination.Prev>
                {paginationItems().length > 10 ? (
                  <>
                    {paginationItems().slice(0, 10)}
                    <Pagination.Ellipsis />
                    {paginationItems().slice(-10)}
                  </>
                ) : (
                  paginationItems()
                )}
                <Pagination.Next onClick={() => nextPage()}>
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{Pageination?.jobs?.length}</b> out of{" "}
              <b>{Pageination?.total_applicants}</b> entries
            </small>
          </Card.Footer>
        </Row>
      </div>
    </>
  );
};

export default RejectedApplicants;
