import produce from "immer";
import {
  LOGIN_SUCCESS,
  LOGOUT,
  RESET_PASSWORD_SUCCESS,
  SET_LOADER,
  ADMIN_UPDATED_SUCCESS,
  LOGIN_FACEBOOK_SUCCESS,
  LOGIN_GOOGLE_SUCCESS,
  UPDATE_AUTH_SUCCESS,
  SIGN_UP_SUCCESS,
  GOOGLE_SIGN_UP_SUCCESS,
  VERIFY_OTP_SUCCESS,
} from "./constants";

const initialState = {
  Auther: null,
  token: null,
  loader: false,
  resetPasswordToken: null,
  userRole: "",
  LoginFacebook: "",
  LoginGoogle: "",
  email: "",
  isEmailVerified: "",
  isCompleteProfile: "",
  isUserForgotPassword: "",
};

const Auth = produce((state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      state.Auther = action.payload.user;
      state.email = action.payload.user?.email;
      state.token = action.payload.access_token;
      state.userRole = action.payload.user?.userRole;
      state.isEmailVerified = action.payload.user?.isEmailVerified;
      state.isCompleteProfile = action.payload.user?.isCompleteProfile;
      state.isUserForgotPassword = "";
      break;
    case UPDATE_AUTH_SUCCESS:
      state.Auther = action.payload.user;

      break;
    case RESET_PASSWORD_SUCCESS:
      // state.resetPasswordToken = action.payload;
      state.email = action.payload;
      state.isUserForgotPassword = true;
      break;
    case SET_LOADER:
      state.loader = action.payload;
      break;
    case LOGOUT:
      state.Auther = null;
      state.token = null;
      state.userRole = "";
      state.isEmailVerified = "";
      state.isCompleteProfile = "";
      break;
    case ADMIN_UPDATED_SUCCESS:
      state.Auther = action.payload;
      break;
    case LOGIN_FACEBOOK_SUCCESS:
      state.LoginFacebook = action.payload;
      break;
    case LOGIN_GOOGLE_SUCCESS:
      state.LoginGoogle = action.payload;
      break;
    case SIGN_UP_SUCCESS:
      state.email = action.payload.email;
      break;
    case GOOGLE_SIGN_UP_SUCCESS:
      state.Auther = action.payload.user;
      state.token = action.payload.access_token;
      state.userRole = action.payload.user?.userRole;
      state.isEmailVerified = action.payload.user?.isEmailVerified;
      state.isCompleteProfile = action.payload.user?.isCompleteProfile;
      state.isUserForgotPassword = "";
      break;
    case VERIFY_OTP_SUCCESS:
      state.Auther = action.payload?.data;
      state.token = action.payload?.access_token;
      state.isEmailVerified = action.payload?.data?.isEmailVerified;
      state.isCompleteProfile = action.payload?.data?.isCompleteProfile;
      state.isUserForgotPassword = "";
      break;
    default:
  }
}, initialState);

export default Auth;
