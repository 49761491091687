import React from "react";
import { Card, Carousel, Image } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"; // Import arrow icons
import { Link } from "react-router-dom";
import Profile from "../assets/img/team/profile.png"; // Import Profile image

const iconStyle = {
  backgroundColor: "black",
  width: '50px',
  borderRadius: "50%", // Optional: make the background circular
  padding: "5px", // Optional: add some padding
  color: "white", // Icon color
  cursor: "pointer", // Change cursor to pointer
};

const RecommendCard = (props) => {
  return (
    <Card border="light" className="shadow-sm introCard">
       <Carousel
            controls={props?.img?.length > 1}
            interval={null}
            indicators={false}
            prevIcon={
              <div style={iconStyle}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
            }
            nextIcon={
              <div style={iconStyle}>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            } // Use styled icons
          >
            {props?.img?.length > 0 ? (
              props?.img.map(
                (
                  item,
                  index // Map through multiple images
                ) => (
                  <Carousel.Item key={index}>
                    <Image src={item?.name ? item?.name : Profile} className="navbar-brand-light" />
                  </Carousel.Item>
                )
              )
            ) : (
              <Carousel.Item>
                <Image src={Profile} className="navbar-brand-light" />
              </Carousel.Item>
            )}
          </Carousel>
      <div className="detailSection">
        <span className="left">
          <Link className="fw-bold" to={`/detailJob/${props.id}`}>
            <h3>{props.name}</h3>
            <h4>{props.type}</h4>
            <p>
              Rate: <span>${props.rate}hr</span>
            </p>
            <p>
              Jobs Completed: <span>{props.completed}</span>
            </p>
          </Link>
        </span>
        <span className="right">
          <span className="starSpan">
            <FontAwesomeIcon icon={faStar} /> {props.star}
          </span>
        </span>
      </div>
    </Card>
  );
};

export default RecommendCard;
