import {
  GET_USER_NOTIFICATION,
  GET_USER_NOTIFICATION_SUCCESS,
  ON_NOTIFICATION,
  ON_NOTIFICATION_SUCCESS,
  ADD_CARD_DETAILS,
  SELECT_CARD,
  DELETE_CARD,
  GET_CARD_DETAILS,
  GET_CARD_DETAILS_SUCCESS,
  GET_BUSINESS_SEEKER,
  GET_BUSINESS_SEEKER_SUCCESS,
  SWITCH_ACCOUNT,
  SWITCH_ACCOUNT_SUCCESS,
  GET_CARD_CHECK,
  GET_CARD_CHECK_SUCCESS,
  ACCOUNT_PROVIDER,
  GET_WALLET_INFO,
  GET_WALLET_INFO_SUCCESS,
  TRANSFER_PAYMENT,
  TRANSFER_PAYMENT_SUCCESS,
  TOGGLE_SCHEDULER,
  TOGGLE_SCHEDULER_SUCCESS,
  SCHEDULE_PAYMENT,
  SCHEDULE_PAYMENT_SUCCESS
} from './constants'

export const getUserNotification = data => ({
  type: GET_USER_NOTIFICATION,
  payload: data
})
export const getUserNotificationSuccess = data => ({
  type: GET_USER_NOTIFICATION_SUCCESS,
  payload: data
})
export const getONNotification = data => ({
  type: ON_NOTIFICATION,
  payload: data
})
export const getONNotificationSuccess = () => ({
  type: ON_NOTIFICATION_SUCCESS
})

export const addCardDetails = data => ({
  type: ADD_CARD_DETAILS,
  payload: data
})
export const selectCard = data => ({
  type: SELECT_CARD,
  payload: data
})
export const deleteCard = data => ({
  type: DELETE_CARD,
  payload: data
})
export const getCardDetails = data => ({
  type: GET_CARD_DETAILS,
  payload: data
})
export const getCardDetailsSuccess = data => ({
  type: GET_CARD_DETAILS_SUCCESS,
  payload: data
})
export const getCardCheck = data => ({
  type: GET_CARD_CHECK,
  payload: data
})
export const getCardCheckSuccess = data => ({
  type: GET_CARD_CHECK_SUCCESS,
  payload: data
})
export const paymentAccountProvider = data => ({
  type: ACCOUNT_PROVIDER,
  payload: data
})
export const getUpgradeBusiness = data => ({
  type: GET_BUSINESS_SEEKER,
  payload: data
})
export const getUpgradeBusinessSuccess = data => ({
  type: GET_BUSINESS_SEEKER_SUCCESS,
  payload: data
})
export const getSwitchAccount = data => ({
  type: SWITCH_ACCOUNT,
  payload: data
})
export const getSwitchAccountSuccess = data => ({
  type: SWITCH_ACCOUNT_SUCCESS,
  payload: data
})
export const getWalletInfo = userId => ({
  type: GET_WALLET_INFO,
  payload: userId
})
export const getWalletInfoSuccess = data => ({
  type: GET_WALLET_INFO_SUCCESS,
  payload: data
})
export const transferPayment = paymentData => ({
  type: TRANSFER_PAYMENT,
  payload: paymentData
})
export const transferPaymentSuccess = data => ({
  type: TRANSFER_PAYMENT_SUCCESS,
  payload: data
})
export const toggleScheduler = schedulerData => ({
  type: TOGGLE_SCHEDULER,
  payload: schedulerData
})
export const toggleSchedulerSuccess = data => ({
  type: TOGGLE_SCHEDULER_SUCCESS,
  payload: data
})
export const schedulePayment = paymentData => ({
  type: SCHEDULE_PAYMENT,
  payload: paymentData
})
export const schedulePaymentSuccess = data => ({
  type: SCHEDULE_PAYMENT_SUCCESS,
  payload: data
})
