import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "Jan", Completed: 250, Paid: 175, Pending: 125, Due: 150 },
  { name: "Feb", Completed: 100, Paid: 80, Pending: 70, Due: 50 },
  { name: "Mar", Completed: 300, Paid: 200, Pending: 150, Due: 100 },
  { name: "Apr", Completed: 250, Paid: 175, Pending: 125, Due: 150 },
  { name: "May", Completed: 120, Paid: 100, Pending: 90, Due: 70 },
  { name: "Jun", Completed: 200, Paid: 150, Pending: 120, Due: 100 },
  { name: "Jul", Completed: 280, Paid: 220, Pending: 200, Due: 160 },
  { name: "Aug", Completed: 100, Paid: 80, Pending: 60, Due: 50 },
];

const totalInvoices = 456;

const InvoiceChart = () => {
  const [filters, setFilters] = useState({
    Completed: true,
    Paid: true,
    Pending: true,
    Due: true,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: checked,
    }));
  };

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    if (value === "All") {
      setFilters({
        Completed: true,
        Paid: true,
        Pending: true,
        Due: true,
      });
    } else {
      setFilters({
        Completed: false,
        Paid: false,
        Pending: false,
        Due: false,
        [value]: true,
      });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        border: "1px solid whitesmoke",
        padding: "20px",
        width: "100%",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div>
          <h2 style={{ color: "#09101D", fontSize: "1.3rem" }}>Invoice</h2>
          <h3 style={{ color: "#66707C" ,fontSize: "1.2rem" }}>
            TOTAL <span style={{ color: "#09101D" ,fontSize: "1.6rem", fontWeight: "bold" }}>{totalInvoices}</span>
          </h3>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "",
            marginTop: "20px",
            width: "100%",
            maxWidth: "400px",
            color: "#09101D",
          }}
        >
          <div
            style={{
              display: "flex",
              //   flexWrap: "wrap",
              alignItems: "center",
              marginBottom: "10px",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <label
              style={{
                margin: "5px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <input
                type="checkbox"
                name="Completed"
                checked={filters.Completed}
                onChange={handleCheckboxChange}
              />
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#003366",
                  marginLeft: "3px",
                }}
              ></div>
              <span style={{ marginLeft: "5px", marginRight: "2px" }}>
                Completed
              </span>
            </label>
            <label
              style={{
                margin: "5px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <input
                type="checkbox"
                name="Paid"
                checked={filters.Paid}
                onChange={handleCheckboxChange}
              />
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#336699",
                  marginLeft: "3px",
                }}
              ></div>
              <span style={{ marginLeft: "5px", marginRight: "2px" }}>
                Paid
              </span>
            </label>
            <label
              style={{
                margin: "5px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <input
                type="checkbox"
                name="Pending"
                checked={filters.Pending}
                onChange={handleCheckboxChange}
              />
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#6699CC",
                  marginLeft: "3px",
                }}
              ></div>
              <span style={{ marginLeft: "5px", marginRight: "2px" }}>
                Pending
              </span>
            </label>
            <label
              style={{
                margin: "5px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <input
                type="checkbox"
                name="Due"
                checked={filters.Due}
                onChange={handleCheckboxChange}
              />
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#99CCFF",
                  marginLeft: "3px",
                }}
              ></div>
              <span style={{ marginLeft: "5px", marginRight: "2px" }}>
                Due
              </span>
            </label>
          </div>
          <select
            style={{ padding: "5px", width: "100%" }}
            onChange={handleDropdownChange}
          >
            <option value="All">All</option>
            <option value="Completed">Completed</option>
            <option value="Paid">Paid</option>
            <option value="Pending">Pending</option>
            <option value="Due">Due</option>
          </select>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {filters.Completed && (
            <Bar dataKey="Completed" stackId="a" fill="#003366" />
          )}
          {filters.Paid && <Bar dataKey="Paid" stackId="a" fill="#336699" />}
          {filters.Pending && (
            <Bar dataKey="Pending" stackId="a" fill="#6699CC" />
          )}
          {filters.Due && <Bar dataKey="Due" stackId="a" fill="#99CCFF" />}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default InvoiceChart;
