import { faAngleLeft, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "@themesberg/react-bootstrap";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { resetPassword } from "../../Redux/auth/actions";
import { Routers } from "../../routes";
import AuthLeft from "../../components/auth/AuthLeftSection.js";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { location } = history;
  // let Array = history.location.search;
  // const newToken = Array.split("=")[1];

  useEffect(() => {
    localStorage.clear();
  }, []);

  const email = useSelector((state) => state.auth.email);
  const [showPassword, setShowPassword] = useState("password");
  const changePasswordState = () => {
    if (showPassword === "password") {
      setShowPassword("text");
    } else {
      setShowPassword("password");
    }
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState("password");
  const changeConfirmPasswordState = () => {
    if (showConfirmPassword === "password") {
      setShowConfirmPassword("text");
    } else {
      setShowConfirmPassword("password");
    }
  };
  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("confirmPassword"), null], "Passwords must match"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  const resetPasswordFormik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      showPasswordToken: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values) => {
      await dispatch(
        resetPassword({
          password: values.password,
          confirmPassword: values.confirmPassword,
          email: email,
          history: history,
          // token: newToken,
        })
      );
    },
  });
  return (
    <main>
      {/* <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5"> */}
      {/* <div className="mx-5"> */}
      <Row className="justify-content-center h-100">
        <AuthLeft />
        <Col
          xs={11}
          md={7}
          className="mt-3 align-items-center justify-content-center h-100"
        >
          <div className="text-center m-3">
            <Row className="align-items-center">
              <Col xs={12}>
                <h3 className="mb-0">Reset password</h3>
              </Col>
            </Row>
          </div>
          <div
            style={{
              position: "relative",
              right: "5%",
              width: "105%",
              zIndex: "-1",
              borderBottom: "2px solid #E7F5FF",
            }}
          ></div>
          <div
            style={{
              width: "95%",
              height: "85vh",
              alignContent: "center",
              overflowY: "auto",
              textAlign: "-webkit-center",
            }}
          >
            <p className="text-center">
              <Card.Link
                as={Link}
                to={Routers.Signin.path}
                className="text-gray-700"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
                sign in
              </Card.Link>
            </p>
            <Form
              style={{ width: "55%", textAlign: "start" }}
              onSubmit={resetPasswordFormik.handleSubmit}
            >
              <Form.Group id="password" className="mb-4">
                <Form.Label>Your Password</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faUnlockAlt} />
                  </InputGroup.Text>
                  <Form.Control
                    //  required
                    type="password"
                    placeholder="Password"
                    value={resetPasswordFormik.values.password}
                    label="Password"
                    name="password"
                    onChange={(e) => {
                      resetPasswordFormik.setFieldValue(
                        "password",
                        e.target.value
                      );
                    }}
                  />
                </InputGroup>
                {resetPasswordFormik.touched.password &&
                resetPasswordFormik.errors.password ? (
                  <div style={{ color: "red" }}>
                    {resetPasswordFormik.errors.password}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group id="confirmPassword" className="mb-4">
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faUnlockAlt} />
                  </InputGroup.Text>
                  <Form.Control
                    //  required
                    type="password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    label="Retype Password"
                    value={resetPasswordFormik.values.confirmPassword}
                    onChange={(e) => {
                      resetPasswordFormik.setFieldValue(
                        "confirmPassword",
                        e.target.value
                      );
                    }}
                  />
                </InputGroup>
                {resetPasswordFormik.touched.confirmPassword &&
                resetPasswordFormik.errors.confirmPassword ? (
                  <div style={{ color: "red" }}>
                    {resetPasswordFormik.errors.confirmPassword}
                  </div>
                ) : null}
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100">
                Reset password
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
      {/* </div> */}
      {/* </section> */}
    </main>
  );
};
export default ResetPassword;
