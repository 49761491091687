import React, { useEffect, useState } from "react";
import { Col, Row, Image } from "@themesberg/react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AuthLeft from "../../components/auth/AuthLeftSection.js";
import { useDispatch, useSelector } from "react-redux";
import { resendOTPRequest, verifyOTPRequest } from "../../Redux/auth/actions";
import goBackIcon from "../../assets/img/icons/arrow_back.png";
import OtpInput from "react-otp-input";
import { Routers } from "../../routes";

const VerifyOTP = () => {
  const [loader, setLoader] = useState(true);
  const [otp, setOtp] = useState("");

  const email = useSelector((state) => state.auth.email);
  const userAuth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useNavigate();

  const resendOTP = () => {
    dispatch(resendOTPRequest({ email, setLoader: setLoader }));
  };

  useEffect(() => {
    if (userAuth?.email && !userAuth?.isEmailVerified && userAuth?.token)
      resendOTP();
  }, []);

  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
    if (otpValue.length === 4) {
      handleOtpVerification(otpValue);
    }
  };

  const handleOtpVerification = (otpValue) => {
    dispatch(
      verifyOTPRequest({
        email,
        code: otpValue,
        setLoader: setLoader,
        history: history,
      })
    );
  };

  return (
    <main>
      <Row className="justify-content-center">
        <AuthLeft />
        <Col
          xs={11}
          md={7}
          className="mt-3 align-items-center justify-content-center h-100"
        >
          <div className="mb-5">
            <div className="text-center mb-3">
              <Row className="align-items-center">
                <Col xs={1}>
                  <Link
                    to={
                      userAuth.isUserForgotPassword
                        ? Routers.ForgotPassword.path
                        : Routers.SignUp.path
                    }
                    className="text-gray-700"
                    style={{
                      color: "#272C3F",
                    }}
                  >
                    <Image src={goBackIcon} />
                  </Link>
                </Col>
                <Col xs={10}>
                  <h3 className="mb-0">Verify OTP</h3>
                </Col>
              </Row>
            </div>
            <div
              style={{
                position: "relative",
                right: "5%",
                width: "105%",
                zIndex: "-1",
                borderBottom: "2px solid #E7F5FF",
              }}
            ></div>
            <div
              className="d-flex"
              style={{
                width: "95%",
                height: "85vh",
                overflowY: "auto",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className="mt-5">
                <OtpInput
                  shouldAutoFocus
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={4}
                  renderSeparator={<span>&nbsp; &nbsp; &nbsp;</span>}
                  focusStyle={{
                    outline: "none",
                    display: "inline",
                  }}
                  renderInput={(props) => (
                    <input
                      {...props}
                      style={{
                        border: "none",
                        textAlign: "center",
                        padding:
                          "10px 15px" /* Adjust padding as needed */ /* Inherit font from surrounding element */,
                        fontSize:
                          "20px" /* Inherit font size from surrounding element */,
                        color:
                          "grey" /* Inherit text color from surrounding element */,
                        width: "100px" /* Adjust width as needed */,
                        height: "150px",
                        background: "#E7F5FF",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                />
              </div>
              <div className="mt-3">
                <Link onClick={resendOTP}>Resend OTP Code</Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default VerifyOTP;
