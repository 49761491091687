import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Nav, Pagination, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonCard from "../../components/CommonCard";
import Navbar from "../../components/Navbar";
import NoRecordFound from "../../components/NoRecordFound";
import Spinner from "../../components/spinner";
import {
  favouriteJobList,
  markAsFavouriteJob,
} from "../../Redux/addJob/actions";

const Favourites = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const JobList = useSelector((state) => state.addJob?.favouriteJob);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    dispatch(favouriteJobList({ page, setLoader }));
  }, [page, dispatch]);

  useEffect(() => {
    if (JobList) setData(JobList);
  }, [JobList]);

  const handlePageChange = (newPage) => {
    setPage((prevPage) => Math.max(1, Math.min(newPage, JobList?.pages)));
  };

  const handleClick = (id, value) => {
    const filteredArray = data?.jobs.filter((_, index) => index !== value);
    setData({ jobs: filteredArray });
    dispatch(markAsFavouriteJob({ id, setLoader }));
  };

  return (
    <>
      <Navbar module={"Favourites"} />
      {loader ? (
        <Spinner />
      ) : (
        <>
          {data?.jobs?.length > 0 ? (
            <Row className="py-2">
              {data.jobs.map((item, index) => (
                <Col
                  key={item.id}
                  lg={2}
                  md={4}
                  sm={6}
                  xs={12}
                  className="pb-3"
                >
                  <CommonCard
                    id={item.id}
                    img={item.images}
                    name={item.name}
                    isFavourite={item.isFavourite}
                    type={item.jobType?.name}
                    rate={item.rate}
                    index={index}
                    completed={"90"}
                    handleClick={handleClick}
                    star={item.rating}
                    page={item.page}
                    limit={item.limit}
                    category={item.categoryType}
                  />
                </Col>
              ))}
              <Col lg={12} className="d-flex justify-content-between">
                <Nav>
                  <Pagination size={"sm"} className="mb-2 mb-lg-0">
                    <Pagination.Prev onClick={() => handlePageChange(page - 1)}>
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </Pagination.Prev>
                    {Array.from({ length: JobList?.pages }, (_, number) => (
                      <Pagination.Item
                        key={number + 1}
                        active={number + 1 === page}
                        onClick={() => handlePageChange(number + 1)}
                      >
                        {number + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => handlePageChange(page + 1)}>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </Pagination.Next>
                  </Pagination>
                </Nav>
                <small className="fw-bold">
                  Showing <b>{JobList?.jobs?.length}</b> out of{" "}
                  <b>{JobList?.total_jobs}</b> entries
                </small>
              </Col>
            </Row>
          ) : (
            <NoRecordFound />
          )}
        </>
      )}
    </>
  );
};

export default Favourites;
