import React, { useEffect, useState } from "react";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faUnlockAlt,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Image,
} from "@themesberg/react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import AuthLeft from "../../components/auth/AuthLeftSection.js";
import { Routers } from "../../routes";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { googleSignUp, signUpRequest } from "../../Redux/auth/actions";
import goBackIcon from "../../assets/img/icons/arrow_back.png";
import { useGoogleLogin } from "@react-oauth/google";
import { fetchToken } from "../../firebase";
import { Checkbox, FormControlLabel } from "@mui/material";

const SignUp = () => {
  const [isTokenFound, setTokenFound] = useState(false);
  const [token, setToken] = useState("");
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    async function tokenFunc() {
      const data = await fetchToken(setTokenFound, setToken);
      return data;
    }
    tokenFunc();
  }, [setTokenFound]);

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    fullName: Yup.string()
      .required("Full name is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .test("phone validation", "Phone number is not valid", value => value && isValidPhoneNumber(value)),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    terms: Yup.boolean()
      .oneOf([true], "You must accept the terms and conditions")
      .required("You must accept the terms and conditions"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signupFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
      fullName: "",
      phoneNumber: "",
      confirmPassword: "",
      terms: true,
    },
    validationSchema: SignupSchema,
    onSubmit: (values, { resetForm }) => {
      const { email, password, fullName, phoneNumber } = values;
      dispatch(
        signUpRequest({
          email,
          password,
          fullName,
          phoneNumber,
          history: navigate,
          resetForm: resetForm,
          setLoader: setLoader,
        })
      );
    },
  });

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userProfileResponse = await fetch(
        "https://www.googleapis.com/oauth2/v2/userinfo",
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        }
      );
      const userProfileData = await userProfileResponse.json();
      if (userProfileData?.email) {
        dispatch(
          googleSignUp({
            data: userProfileData,
            email: userProfileData?.email,
            name: userProfileData?.name,
            socialKey: "google",
            history: navigate,
            setLoader: setLoader,
          })
        );
      }
    },
  });

  return (
    <main>
      <Row className="justify-content-center h-100">
        <AuthLeft />
        <Col
          xs={11}
          md={7}
          className="mt-3 align-items-center justify-content-center h-100"
        >
          <div className="mb-5">
            <div className="text-center mb-3">
              <Row className="align-items-center">
                <Col xs={1}>
                  <Link
                    to="/"
                    className="text-gray-700"
                    style={{ color: "#272C3F" }}
                  >
                    <Image src={goBackIcon} />
                  </Link>
                </Col>
                <Col xs={10}>
                  <h3 className="mb-0">Create an account</h3>
                </Col>
              </Row>
            </div>
            <div
              style={{
                position: "relative",
                right: "5%",
                width: "105%",
                zIndex: "-1",
                borderBottom: "2px solid #E7F5FF",
              }}
            ></div>
            <div
              style={{
                width: "95%",
                height: "85vh",
                alignContent: "center",
                overflowY: "auto",
              }}
            >
              <Form className="mt-4" onSubmit={signupFormik.handleSubmit}>
                <Form.Group id="fullname" className="mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="John Doe"
                    onChange={signupFormik.handleChange}
                    onBlur={signupFormik.handleBlur}
                    value={signupFormik.values.fullName}
                    name="fullName"
                  />
                  {signupFormik.touched.fullName && signupFormik.errors.fullName ? (
                    <div className="text-danger ml-2 mt-2">
                      {signupFormik.errors.fullName}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group id="email" className="mb-3">
                  <Form.Label>Your Email</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                    <Form.Control
                      type="email"
                      placeholder="example@company.com"
                      onChange={signupFormik.handleChange}
                      onBlur={signupFormik.handleBlur}
                      value={signupFormik.values.email}
                      name="email"
                    />
                  </InputGroup>
                  {signupFormik.touched.email && signupFormik.errors.email ? (
                    <div className="text-danger ml-2 mt-2">
                      {signupFormik.errors.email}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group id="phoneNumber" className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <PhoneInput
                    international
                    defaultCountry="CA"
                    value={signupFormik.values.phoneNumber}
                    onChange={(phoneNumber) => signupFormik.setFieldValue('phoneNumber', phoneNumber)}
                    onBlur={() => signupFormik.setFieldTouched('phoneNumber', true)}
                  />
                  {signupFormik.touched.phoneNumber && signupFormik.errors.phoneNumber && (
                    <div className="text-danger ml-2 mt-2">{signupFormik.errors.phoneNumber}</div>
                  )}
                </Form.Group>
                <Form.Group id="password" className="mb-3">
                  <Form.Label>Your Password</Form.Label>
                  <InputGroup>
                    <InputGroup.Text
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                      />
                    </InputGroup.Text>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      onChange={signupFormik.handleChange}
                      onBlur={signupFormik.handleBlur}
                      value={signupFormik.values.password}
                      name="password"
                    />
                  </InputGroup>
                  {signupFormik.touched.password &&
                  signupFormik.errors.password ? (
                    <div className="text-danger ml-2 mt-2">
                      {signupFormik.errors.password}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group id="confirmPassword" className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup>
                    <InputGroup.Text
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      <FontAwesomeIcon
                        icon={showConfirmPassword ? faEyeSlash : faEye}
                      />
                    </InputGroup.Text>
                    <Form.Control
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      onChange={signupFormik.handleChange}
                      onBlur={signupFormik.handleBlur}
                      value={signupFormik.values.confirmPassword}
                      name="confirmPassword"
                    />
                  </InputGroup>
                  {signupFormik.touched.confirmPassword &&
                  signupFormik.errors.confirmPassword ? (
                    <div className="text-danger ml-2 mt-2">
                      {signupFormik.errors.confirmPassword}
                    </div>
                  ) : null}
                </Form.Group>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...signupFormik.getFieldProps('terms')}
                      defaultChecked={signupFormik.values.terms}
                    />
                  }
                  label={
                    <>
                      I agree to the{" "}
                      <Link to="/terms-public">terms and conditions</Link>
                    </>
                  }
                />
                {signupFormik.touched.terms && signupFormik.errors.terms ? (
                  <div className="text-danger ml-2 mt-2">
                    {signupFormik.errors.terms}
                  </div>
                ) : null}
                <Button
                  variant="primary"
                  type="submit"
                  className="w-100"
                  disabled={loader}
                >
                  {loader ? "Loading..." : "Sign up"}
                </Button>
              </Form>
              <div className="mt-3 mb-4 text-center">
                <span className="fw-normal">or</span>
              </div>
              <div className="d-flex justify-content-center my-4">
                <Button
                  variant="outline-light"
                  className="btn-icon-only btn-pill text-facebook me-2"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </Button>
                <Button
                  variant="outline-light"
                  className="btn-icon-only btn-pill text-danger"
                  onClick={googleLogin}
                >
                  <FontAwesomeIcon icon={faGoogle} />
                </Button>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-4">
                <span className="fw-normal">
                  Already have an account?
                  <Card.Link
                    as={Link}
                    to={Routers.Signin.path}
                    className="fw-bold"
                  >
                    {` Login here `}
                  </Card.Link>
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default SignUp;
