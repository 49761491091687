import React from "react";
import { Col, Image } from "@themesberg/react-bootstrap";
import BgImage from "../../assets/img/auth-left-section-theme.png";

const AuthLeft = () => {
  return (
    <Col
      xs={11}
      md={5}
      className="justify-content-center align-items-center h-100"
    >
      <div className="position-relative">
        <Image
          src={BgImage}
          alt="Sign In Illustration"
          fluid
          className="p-3"
          style={{ height: "100vh", width: "100%" }}
        />
        <h1 className="position-absolute top-50 start-50 translate-middle text-white">joblinxs</h1>
      </div>
    </Col>
  );
};

export default AuthLeft;