export const Routers = {
  DashboardOverview: { path: "/dashboard" },
  AdminDashBoard: { path: "/adminDashBoard" },
  NewArrivalProvider: { path: "/Newarrivalproviders" },
  TopRatedProviders: { path: "/TopRatedProviders" },
  Recommended: { path: "/recommended" },
  Profile: { path: "/profile" },
  CreateProfile: { path: "/createProfile" },
  CreateJob: { path: "/createJob" },
  CreateWrokerJob: { path: "/create_worker_job" },
  Applicants: { path: "/applicants/:jobId" },
  LogHours: { path: "/loghours/:jobId" },
  ExtendTime: { path: "/extendTime/:jobId" },
  LogHoursDetails: { path: "/loghoursdetails/:jobId" },
  UpdateJob: { path: "/updateJob/:id" },
  UpdateGig: { path: "/updateGig/:id" },
  DetailJob: { path: "/detailJob/:id" },
  MyJobDetail: { path: "/job-management/jobDetail/:id" },
  DetailProvider: { path: "/detailProvider/:id" },
  WorkExperience: { path: "/workexperience" },
  Achievement: { path: "/achievement" },
  UserDetail: { path: "/user_management/userDetail" },
  JobDetails: { path: "/jobDetails" },
  PayementMethod: { path: "/PayementMethod" },
  Favourites: { path: "/favourites" },
  Search: { path: "/search" },
  AddCard: { path: "/add_card" },
  Wallet: { path: "/wallet" },
  transactionHistory: { path: "/transaction_history" },
  BankConnect: { path: "/connect_bank" },
  Settings: { path: "/settings" },
  ChangePassword: { path: "/changePassword" },
  Contactus: { path: "/contact-us" },
  TermsandConditions: { path: "/terms-public" },
  PrivacyPolicyPublic: { path: "/privacy-public" },
  TermsandConditionsPublic: { path: "/terms-public" },
  PrivacyPolicy: { path: "/privacy-public" },
  Job: { path: "/job" },
  Gigs: { path: "/gigs" },
  EditProfile: { path: "/profile/editProfile" },
  Transactions: { path: "/transactions" },
  UserManagement: { path: "/user_management" },
  Categories: { path: "/categories" },
  SubCategories: { path: "/subcategories" },
  BusinessCategory: { path: "/businessCategory" },
  BusinessSubCategory: { path: "/businessSubCategory" },
  Categories_Management: { path: "/category-management" },
  Report_Management: { path: "/Report-management" },
  Dispute_Management: { path: "/dispute-management" },
  Job_Management: { path: "/job-management" },
  Users: { path: "/users" },
  MyTickets: { path: "/tickets" },
  EditAdminProfile: { path: "/AdminProfile/editAdminProfile" },
  AdminProfile: { path: "/AdminProfile" },
  Signin: { path: "/" },
  SignUp: { path: "/signup" },
  Verify_OTP: { path: "/verify-otp" },
  ForgotPassword: { path: "/forgot-password" },
  ResetPassword: { path: "/reset-password" },
  NotFound: { path: "*" },
  Chat: { path: "/chat" },
  Events: { path: "/events" },
};
