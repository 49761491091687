export const ADD_JOB = "add_Job";
export const APPLY_JOB = "APPLY_JOB";
export const ADD_WORKER_JOB = "ADD_WORKER_JOB";
export const ADD_JOB_SUCCESS = "add_Job_Success";
export const GET_DAYS = "GET_DAYS";
export const GET_DAYS_SUCCESS = "GET_DAYS_SUCCESS";
export const GET_JOB = "Get_Job";
export const GET_JOB_SUCCESS = "Get_Job_Success";
export const GET_GIGS = "GET_GIGS";
export const GET_GIGS_SUCCESS = "GET_GIGS_SUCCESS";
export const MARK_AS_FAVOURITE_JOB = "MARK_AS_FAVOURITE_JOB";
export const FAVOURITE_JOB_LIST = "FAVOURITE_JOB_LIST";
export const FAVOURITE_JOB_LIST_SUCCESS = "FAVOURITE_JOB_LIST_SUCCESS";
export const DELETE_ADD_JOB = "Delete_Add_Job";
export const JOB_BY_ID = "job_By_Id";
export const JOB_BY_ID_SUCCESS = "job_By_Id_Success";
export const UPDATE_JOB = "Update_Job";
export const UPDATE_JOB_SUCCESS = "Update_Job_Success";
export const GET_JOB_APPLICANTS = "get_Job_Applicants";
export const GET_JOB_APPLICANTS_SUCCESS = "get_Job_Applicants_Success";
export const CONFIRM_APPLICANTS = "Confirm_Applicants";
export const CONFIRM_APPLICANTS_SUCCESS = "Confirm_Applicants_Success";
export const CONFIRM_JOB_BY_WORKER = "CONFIRM_JOB_BY_WORKER";
export const REJECTED_APPLICANTS="REJECTED_APPLICANTS";
export const REJECTED_APPLICANTS_SUCCESS = "REJECTED_APPLICANTS_SUCCESS";
export const GET_LOG_HOURS = "Get_Log_Hours";
export const POST_LOG_HOURS = "POST_LOG_HOURS";
export const CONFIRM_HIRING = "CONFIRM_HIRING";
export const START_JOB = "START_JOB";
export const GET_LOG_HOURS_SUCCESS = "Get_Log_Hours_Success";
export const GET_SHORTLIST_APPLICANTS_SUCCESS= "GET_SHORTLIST_APPLICANTS_SUCCESS";
export const GET_SHORTLIST_APPLICANTS = "GET_SHORTLIST_APPLICANTS";
export const SHORTLIST_APPLICANTS = "SHORTLIST_APPLICANTS";
export const APPROVED_LOG_HOURS = "Approved_Log_Hours";
export const APPROVED_LOG_HOURS_SUCCESS = "Approved_Log_Hours_Success";
export const GET_SINGLE_USER = "Get_Single_User";
export const GET_SINGLE_USER_SUCCESS = "Get_Single_User_Success";
export const RATE_PROVIDER = "RATE_PROVIDER";
export const GET_HIRED_APPLICANTS = "GET_HIRED_APPLICANTS";
export const GET_HIRED_APPLICANTS_SUCCESS = "GET_HIRED_APPLICANTS_SUCCESS";
export const COMPLETE_JOB = "COMPLETE_JOB";
export const CONFIRM_JOB = "CONFIRM_JOB";
export const GET_APPLICANTS_BYUSERID = "GET_APPLICANTS_BYUSERID";
export const GET_APPLICANTS_BYUSERID_SUCCESS = "GET_APPLICANTS_BYUSERID_SUCCESS";
export const EMERGENCY_JOB = "EMERGENCY_JOB";
export const SEND_OFFER = "Send_Offer";
export const SEND_OFFER_SUCCESS = "Send_Offer_Success";
export const EXTEND_TIME = "EXTEND_TIME";
export const EXTEND_TIME_SUCCESS = "Extend_Time_Success";
export const GET_EXTEND = "GET_EXTEND";
export const GET_EXTEND_SUCCESS = "GET_EXTEND_Success";