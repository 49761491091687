import { Button, Modal } from "@themesberg/react-bootstrap";
import { logoutRequest } from "../Redux/auth/actions";
import { useDispatch } from "react-redux";

const LogOutModal = ({ closeModal, showDefaultCategory, auth }) => {
  const dispatch = useDispatch();
  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showDefaultCategory}
      onHide={closeModal}
    >
      <Modal.Header>
        <Modal.Title className="h5">{"Confirmation"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <Modal.Title className="h5 text-left">
          Are you sure you want to logout?
        </Modal.Title>

        <div class="d-flex justify-content-between mt-3">
          <Button
            variant="white shadow"
            color="dark"
            onClick={closeModal}
            type="button"
            size="sm"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            color="dark"
            type="button"
            onClick={() => {
              dispatch(logoutRequest({ user: auth?.Auther?.id }));
            }}
            size="sm"
          >
            Logout
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default LogOutModal;
