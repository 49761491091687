import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Modal } from '@themesberg/react-bootstrap';
import Select from 'react-select';
import { getCategoryList, getUserSelectedCategoryList, updateUserSelectedCategoryList } from '../../../Redux/Category/actions';

const CategoryModal = ({ userId, onClose }) => {
  const dispatch = useDispatch();
  const CategoryData = useSelector(state => state?.Category?.getCategoryList);
  const userSelectedCategoryList = useSelector(state => state?.Category?.userSelectedCategoryList);
  const [selectedParentCategories, setSelectedParentCategories] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [categorySubcategoryMapping, setCategorySubcategoryMapping] = useState({});
  const [dataset, setDataset] = useState([]);

  useEffect(() => {
    dispatch(getCategoryList({ search: '' }));
    dispatch(getUserSelectedCategoryList({ userId }));
  }, [dispatch, userId]);

  useEffect(() => {
    if (userSelectedCategoryList.length > 0) {
      const parentCategories = userSelectedCategoryList.map(item => {
        const category = CategoryData.find(cat => cat.id === item.user_categories.id);
        return category ? { value: category.id, label: category.title } : null;
      }).filter(Boolean);

      const subCategories = userSelectedCategoryList.flatMap(item => {
        const parentCategory = CategoryData.find(cat => cat.id === item.user_categories.id);
        return parentCategory ? parentCategory.sub_Categories.map(subCat => {
          return subCat.id === item.category.id ? { value: subCat.id, label: subCat.title } : null;
        }).filter(Boolean) : [];
      });

      setSelectedParentCategories(parentCategories);
      setSubCategoryOptions(subCategories);
      setCategorySubcategoryMapping(userSelectedCategoryList.reduce((acc, item) => {
        if (!acc[item.user_categories.id]) {
          acc[item.user_categories.id] = [];
        }
        acc[item.user_categories.id].push(item.category.id);
        return acc;
      }, {}));
    }
  }, [userSelectedCategoryList, CategoryData]);

  useEffect(() => {
    const newSubCategories = [];
    selectedParentCategories.forEach(parent => {
      const parentCategory = CategoryData.find(cat => cat.id === parent.value);
      if (parentCategory && parentCategory.sub_Categories) {
        newSubCategories.push(...parentCategory.sub_Categories);
      }
    });
    setSubCategoryOptions(
      newSubCategories
        .map(subCategory => ({
          value: subCategory.id,
          label: subCategory.title
        }))
        .filter((subCategory, index, self) =>
          index === self.findIndex((t) => (
            t.value === subCategory.value
          ))
        )
    );
  }, [selectedParentCategories, CategoryData]);

  useEffect(() => {
    const newDataset = Object.keys(categorySubcategoryMapping).map(
      parentId => ({
        mainCategoryId: parentId,
        subCategories: categorySubcategoryMapping[parentId]
      })
    );
    setDataset(newDataset);
  }, [categorySubcategoryMapping]);

  const handleParentCategoryChange = selectedOptions => {
    setSelectedParentCategories(selectedOptions || []);
    setCategorySubcategoryMapping(prevMapping => {
      const newMapping = {};
      (selectedOptions || []).forEach(option => {
        if (prevMapping[option.value]) {
          newMapping[option.value] = prevMapping[option.value];
        } else {
          newMapping[option.value] = [];
        }
      });
      return newMapping;
    });
  };

  const handleSubCategoryChange = selectedOptions => {
    setCategorySubcategoryMapping(prevMapping => {
      const newMapping = { ...prevMapping };
      selectedParentCategories.forEach(parent => {
        newMapping[parent.value] = selectedOptions
          ? selectedOptions
              .filter(option => {
                const parentCategory = CategoryData.find(
                  cat => cat.id === parent.value
                );
                return parentCategory.sub_Categories.some(
                  subCat => subCat.id === option.value
                );
              })
              .map(option => option.value)
          : [];
      });
      return newMapping;
    });
  };

  const categoryOptions = CategoryData.map(category => ({
    value: category.id,
    label: category.title
  }));

  const updateCategories = () => {
    dispatch(updateUserSelectedCategoryList({ data: dataset, setLoader: () => {} }));
    onClose();
  };

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Update Categories</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <Form.Group className='col my-2'>
          <Form.Label>Categories</Form.Label>
          <Select
            isMulti
            options={categoryOptions}
            className='basic-multi-select'
            classNamePrefix='select'
            onChange={handleParentCategoryChange}
            value={selectedParentCategories}
          />
        </Form.Group>

        <Form.Group className='col my-2'>
          <Form.Label>Sub Categories</Form.Label>
          <Select
            isMulti
            options={subCategoryOptions}
            className='basic-multi-select'
            classNamePrefix='select'
            onChange={handleSubCategoryChange}
            value={selectedParentCategories.flatMap(parent =>
              categorySubcategoryMapping[parent.value]?.map(
                subId => {
                  const subCat = subCategoryOptions.find(
                    option => option.value === subId
                  );
                  return subCat || null;
                }
              ).filter(Boolean)
            )}
            isDisabled={subCategoryOptions.length === 0}
          />
        </Form.Group>

        <Button variant="primary" onClick={updateCategories}>
          Update Categories
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default CategoryModal;