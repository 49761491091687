import React, { useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Image, Nav, Navbar } from "@themesberg/react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import SimpleBar from "simplebar-react";

import { Routers } from "../routes";
import { CapitalizeFirstLetter } from "../utils/Global";
import { Constant } from "../utils/constant";
import AdminRoutes from "../Routes/adminRoutes";
import SubAdminRoutes from "../Routes/subAdminRoutes";
import CustomerRoutes from "../Routes/customerRoutes";
import LogOutModal from "./logOutModal";

const Sidebar = () => {
  const [show, setShow] = useState(false);
  const [showDefaultCategory, setShowDefaultCategory] = useState(false);
  const showClass = show ? "show" : "";
  const handleClosesCategory = () => {
    setShowDefaultCategory(false);
  };
  const auth = useSelector((state) => state.auth);
  const onCollapse = () => setShow(!show);
  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routers.DashboardOverview.path}
        ></Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex align-items-center justify-content-between justify-content-md-center pb-3">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={auth?.Auther?.profileImg}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h4>{auth?.Auther?.fullName}</h4>
                  <span>
                    {auth?.Auther?.role?.name == Constant.Role.ADMIN
                      ? CapitalizeFirstLetter(auth?.Auther?.role?.name)
                      : CapitalizeFirstLetter(auth?.Auther?.role?.name)}
                  </span>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0 mt-4">
              {auth?.Auther?.role?.name == Constant.Role.ADMIN ? (
                <AdminRoutes
                  setShowDefaultCategory={setShowDefaultCategory}
                  showDefaultCategory={showDefaultCategory}
                  closeModal={handleClosesCategory}
                />
              ) : auth?.Auther?.role?.name == Constant.Role.SUB_ADMIN ? (
                <SubAdminRoutes
                  setShowDefaultCategory={setShowDefaultCategory}
                  showDefaultCategory={showDefaultCategory}
                  closeModal={handleClosesCategory}
                />
              ) : auth?.Auther?.role?.name == Constant.Role.CUSTOMER ? (
                <CustomerRoutes
                  setShowDefaultCategory={setShowDefaultCategory}
                  showDefaultCategory={showDefaultCategory}
                  closeModal={handleClosesCategory}
                  worker={false}
                />
              ) : (
                <CustomerRoutes
                  setShowDefaultCategory={setShowDefaultCategory}
                  showDefaultCategory={showDefaultCategory}
                  closeModal={handleClosesCategory}
                  worker={true}
                />
              )}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
      <LogOutModal
        setShowDefaultCategory={setShowDefaultCategory}
        showDefaultCategory={showDefaultCategory}
        closeModal={handleClosesCategory}
        auth={auth}
      />
    </>
  );
};

export default Sidebar;