import React, { useEffect, useState } from "react";
import { Col, Row, Pagination, Card, Nav } from "@themesberg/react-bootstrap";
import Navbar from "../../components/Navbar";
import CommonCard from "../../components/CommonCard";
import { useDispatch, useSelector } from "react-redux";
import { getSeekerListing } from "../../Redux/Dashboard/actions";
import { markAsFavouriteJob } from "../../Redux/addJob/actions";
import NoRecordFound from "../../components/NoRecordFound";
import Spinner from "../../components/spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import Profile from "../../assets/img/team/profile.png";
import ProfileCard from "../../components/profileCard";

const DashboardOverview = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const SeekerList = useSelector((state) => state?.Seeker?.getSeekerListing);
  const auth = useSelector((state) => state.auth.Auther);

  useEffect(() => {
    dispatch(getSeekerListing({ page, limit: 10, setLoader, search:"" }));
  }, [dispatch, page]);

  useEffect(() => {
    if (SeekerList) {
      setLoader(false);
    }
  }, [SeekerList]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= SeekerList?.pages) {
      setPage(newPage);
    }
  };

  const handleClick = (id, index, isFavourite) => {
    const updatedData = [...SeekerList.data];
    updatedData[index].isFavourite = !isFavourite;
    dispatch(markAsFavouriteJob({ id, setLoader }));
    // Update local state optimistically
    setPage(updatedData);
  };

  const renderCards = (items) => {
    return items?.map((value, index) => (
      <Col
        lg={2}
        md={4}
        sm={6}
        xs={12}
        className="pb-3"
        key={value.id || index}
      >
        {auth?.role?.name === "customer" ? (
          <ProfileCard
            img={value?.user?.profileImg || Profile}
            name={value.user?.fullName}
            type={"IT"}
            id={value.user?.id}
            star={value.user?.profile_rating}
            completed={value.user?.completeJobs || "0"}
          />
        ) : (
          <CommonCard
            img={value?.images || Profile}
            name={value.name}
            title="recommended"
            isFavourite={value.isFavourite}
            setLoader={setLoader}
            index={index}
            id={value.id}
            handleClick={handleClick}
            paymentType={value.paymentType}
            rate={value.rate}
            completed={"10"}
            star={value.rating}
          />
        )}
      </Col>
    ));
  };

  return (
    <>
      <Navbar module={"Recommended For You"} />
      <Row className="pt-2 pb-4">
        {loader ? (
          <Spinner />
        ) : (
          <>
            {SeekerList?.data?.length > 0 ? (
              <>
                {renderCards(SeekerList.data)}
                <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                  <Nav>
                    <Pagination size={"sm"} className="mb-2 mb-lg-0">
                      <Pagination.Prev
                        onClick={() => handlePageChange(page - 1)}
                      >
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                      </Pagination.Prev>
                      {Array.from({ length: SeekerList.pages }, (_, number) => (
                        <Pagination.Item
                          key={number + 1}
                          active={number + 1 === page}
                          onClick={() => handlePageChange(number + 1)}
                        >
                          {number + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() => handlePageChange(page + 1)}
                      >
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </Pagination.Next>
                    </Pagination>
                  </Nav>
                </Card.Footer>
              </>
            ) : (
              <NoRecordFound />
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default DashboardOverview;
