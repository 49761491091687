import React from 'react';
import mapSvg from "./map.svg";

const RevenueByLocation = () => {
  const data = [
    { location: 'New York', revenue: 50000 },
    { location: 'San Francisco', revenue: 30000 },
    { location: 'Sydney', revenue: 40000 },
    { location: 'Singapore', revenue: 20000 },
  ];

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Revenue by Location</h2>
      <div style={styles.map}>
        <img src={mapSvg}  alt="World Map" style={styles.mapImage} />
      </div>
      {data.map((item, index) => (
        <div key={index} style={styles.barContainer}>
          <span style={styles.location}>{item.location}</span>
          <div style={styles.barBackground}>
            <div
              style={{
                ...styles.bar,
                width: `${(item.revenue / 50000) * 100}%`, // 50K is the maximum revenue
              }}
            />
          </div>
          <span style={styles.revenue}>{item.revenue / 1000}K</span>
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    margin: '0 auto',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    backgroundColor: '#fff',
  },
  title: {
    fontSize: '1.3rem',
    color: '#09101D',
    fontWeight: 'bold',
    marginBottom: '20px',
    textAlign: 'center',
  },
  map: {
    textAlign: 'center',
    marginBottom: '70px',
  },
  mapImage: {
    width: '100%',
    height: 'auto',
  },
  barContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  location: {
    flex: '1',
    fontSize: '14px',
  },
  barBackground: {
    flex: '3',
    height: '10px',
    borderRadius: '5px',
    backgroundColor: '#e0e0e0',
    margin: '0 10px',
  },
  bar: {
    height: '10px',
    borderRadius: '5px',
    backgroundColor: '#007bff',
  },
  revenue: {
    flex: '1',
    fontSize: '14px',
    textAlign: 'right',
  },
};

export default RevenueByLocation;
