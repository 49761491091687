import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Modal,
  Row,
} from "@themesberg/react-bootstrap";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Navbar from "../../components/Navbar";
import NoRecordFound from "../../components/NoRecordFound";
import {
  deleteCategory,
  updateCategory,
  addSubAdminCategory,
  getSingleList,
} from "../../Redux/Category/actions";

const SubCategories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const CategoryList = useSelector((state) => state?.Category?.getCategoryList);
  const SingleList = useSelector(
    (state) => state?.Category?.SingleList?.sub_Categories
  );
  const MainCategory = useSelector((state) => state?.Category?.SingleList);
  const auth = useSelector((state) => state.auth?.Auther);
  const [search, setSearch] = useState("");
  const [adminId, setAdminId] = useState(0);
  const [delCategory, setDelCategory] = useState(false);
  const categoryId = window.location.search.split("?")[1];
  useEffect(() => {
    dispatch(
      getSingleList({
        id: categoryId,
        search: search,
      })
    );
  }, [search]);
  const [showDefault, setShowDefault] = useState(false);
  const [subModal, setSubModel] = useState(false);

  const handleClose = () => {
    setEdit(false);
    setShowDefault(false);
    setDelCategory(false);
    CategoryFormik.resetForm();
  };
  const handleSubCategory = () => {
    setEdit(false);
    setSubModel(false);
    setDelCategory(false);
    CategoryFormik.resetForm();
  };
  const [selectedImage, setSelectedImage] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleDelete = (value) => {
    dispatch(
      deleteCategory({
        userId: adminId,
        search: search,
      })
    );
  };

  const CategorySchema = Yup.object().shape({
    title: Yup.string().trim().required("Category Name is required"),
    details: Yup.string().trim().required("description is required"),
  });
  const CategoryFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selectedItem?.id ? selectedItem?.id : "",
      title: selectedItem?.title ? selectedItem?.title : "",
      details: selectedItem?.details ? selectedItem?.details : "",
      remember: true,
    },

    validationSchema: CategorySchema,
    onSubmit: async (values, action) => {
      selectedItem
        ? dispatch(
            updateCategory({
              id: values.id,
              title: values.title,
              categoryImg: selectedImage,
              details: values.details,
              setReset: action.resetForm,
              setShowDefault: setShowDefault,
              setSelectedImage: setSelectedImage,

              history: navigate,
            })
          )
        : dispatch(
            addSubAdminCategory({
              categoryId: MainCategory?.id,
              title: values.title,
              details: values.details,
              categoryImg: selectedImage,
              setReset: action.resetForm,
              setShowDefault: setShowDefault,
              showDefault: showDefault,
              setSelectedImage: setSelectedImage,
              setSubModel: setSubModel,
              subModal: subModal,
            })
          );
    },
  });
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const addSubCategories = () => {
    setSubModel(true);
    setEdit(false);
  };
  return (
    <>
      <Navbar module={"Sub-Categories"} />
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/categories">
          Categories
        </Link>
        <Typography color="text.primary">Sub-Catogories</Typography>
      </Breadcrumbs>
      <Row className="py-2 justify-content-between align-items-baseline">
        <Col lg={3} md={5}>
          <Form.Group className="mt-3">
            <Form.Control
              type="text"
              select
              placeholder="Search"
              label="Search"
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
          </Form.Group>
        </Col>
        {auth?.role?.name === "admin" && (
          <Col lg={6} md={5} className="justify-content-end d-flex">
            <Button
              variant="primary"
              className="mx-2"
              onClick={() => addSubCategories()}
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 12H8M12 8V12V8ZM12 12V16V12ZM12 12H16H12Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="white"
                  stroke-width="2"
                />
              </svg>
              {"  "}
              Add Sub-Category
            </Button>
          </Col>
        )}
        <Col lg={12} md={12} sm={12} xs={12} className="pt-4 pb-1">
          <div className="d-flex justify-content-between"></div>
        </Col>
      </Row>
      {SingleList?.length ? (
        <>
          <Row className="pb-1">
            {SingleList?.map((value) => {
              return (
                <>
                  <Col
                    lg={4}
                    md={12}
                    xs={12}
                    sm={12}
                    className="pb-3 introCardParent"
                  >
                    <Card border="light" className="shadow-sm introCard">
                      <Image
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                          borderRadius: "10px",
                        }}
                        src={value.categoryImg}
                        className="navbar-brand-light"
                      />
                      <div className="detailSection">
                        <span className="left">
                          <h3>{value.title}</h3>
                          <p>{value.details}</p>
                        </span>
                      </div>
                    </Card>
                  </Col>
                </>
              );
            })}
          </Row>
        </>
      ) : (
        <>
          <NoRecordFound />
        </>
      )}

      <Modal as={Modal.Dialog} centered show={delCategory} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h5">Delete User</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              Are you sure you want to delete this Category?
            </Form.Group>
            <Form.Group>
              <div class="d-grid gap-2 col-4 text-center mt-3 mx-auto">
                <Button
                  variant="primary"
                  // onHide={handleClose}
                  color="dark"
                  size="sm"
                  // type="submit"
                  onClick={() => {
                    handleDelete();
                    handleClose();
                  }}
                >
                  Delete
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal */}
      <Modal as={Modal.Dialog} centered show={showDefault}>
        <Modal.Header>
          <Modal.Title className="h5">
            {isEdit ? "Edit Category" : "Add Category"}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={CategoryFormik.handleSubmit}>
            <Form.Group>
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                // required
                type="text"
                placeholder="Enter category Name"
                value={CategoryFormik.values.title}
                name="title"
                label="title"
                onChange={(e) => {
                  CategoryFormik.setFieldValue("title", e.target.value);
                }}
              />
              {CategoryFormik.touched.title && CategoryFormik.errors.title ? (
                <div style={{ color: "red" }}>
                  {CategoryFormik.errors.title}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                // required
                type="text"
                placeholder="Description"
                value={CategoryFormik.values.details}
                name="details"
                label="details"
                onChange={(e) => {
                  CategoryFormik.setFieldValue("details", e.target.value);
                }}
              />
              {CategoryFormik.touched.details &&
              CategoryFormik.errors.details ? (
                <div style={{ color: "red" }}>
                  {CategoryFormik.errors.details}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                type="file"
                onChange={imageChange}
                accept="image/png, image/jpg, image/jpeg"
              />

              <div class="d-grid gap-2 col-4 text-center mt-3 mx-auto">
                <Button
                  variant="primary"
                  // onHide={handleClose}
                  color="dark"
                  size="sm"
                  type="submit"
                >
                  {isEdit ? "Update" : "Save"}
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Sub-Category */}
      <Modal as={Modal.Dialog} centered show={subModal}>
        <Modal.Header>
          <Modal.Title className="h5">
            {isEdit ? "Edit Category" : "Add Sub-Category"}
          </Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleSubCategory}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={CategoryFormik.handleSubmit}>
            <Form.Group>
              <Form.Label>Sub-Category Name</Form.Label>
              <Form.Control
                // required
                type="text"
                placeholder="Enter category Name"
                value={CategoryFormik.values.title}
                name="title"
                label="title"
                onChange={(e) => {
                  CategoryFormik.setFieldValue("title", e.target.value);
                }}
              />
              {CategoryFormik.touched.title && CategoryFormik.errors.title ? (
                <div style={{ color: "red" }}>
                  {CategoryFormik.errors.title}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                // required
                type="text"
                placeholder="Description"
                value={CategoryFormik.values.details}
                name="details"
                label="details"
                onChange={(e) => {
                  CategoryFormik.setFieldValue("details", e.target.value);
                }}
              />
              {CategoryFormik.touched.details &&
              CategoryFormik.errors.details ? (
                <div style={{ color: "red" }}>
                  {CategoryFormik.errors.details}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="title"
                value={MainCategory?.title}
                name="title"
                label="title"
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                type="file"
                onChange={imageChange}
                accept="image/png, image/jpg, image/jpeg"
              />

              <div class="d-grid gap-2 col-4 text-center mt-3 mx-auto">
                <Button variant="primary" color="dark" size="sm" type="submit">
                  {isEdit ? "Update" : "Save"}
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SubCategories;
