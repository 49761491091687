import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "@themesberg/react-bootstrap";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { forgotPassword } from "../../Redux/auth/actions";
import { Routers } from "../../routes";
import AuthLeft from "../../components/auth/AuthLeftSection.js";

const ForgetPage = () => {
  useEffect(() => {
    localStorage.clear();
  }, []);
  const history = useNavigate();
  const dispatch = useDispatch();

  const ForgetSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const forgetFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgetSchema,
    onSubmit: async (values, { resetForm }) => {
      await dispatch(
        forgotPassword({
          email: values.email,
          history: history,
        })
      );
      resetForm();
    },
  });
  return (
    <main>
      {/* <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center"> */}
      {/* <Container> */}
      <Row className="justify-content-center h-100">
        <AuthLeft />
        <Col
          xs={11}
          md={7}
          className="mt-3 align-items-center justify-content-center h-100"
        >
          <div className="text-center m-3">
            <Row className="align-items-center">
              <Col xs={12}>
                <h3 className="mb-0">Forgot Password</h3>
              </Col>
            </Row>
          </div>
          <div
            style={{
              position: "relative",
              right: "5%",
              width: "105%",
              zIndex: "-1",
              borderBottom: "2px solid #E7F5FF",
            }}
          ></div>
          <div
            style={{
              width: "95%",
              height: "85vh",
              alignContent: "center",
              overflowY: "auto",
              textAlign: "-webkit-center",
            }}
          >
            <h3>Forgot your password?</h3>
            <p className="mb-4">
              Don't fret! Just type in your email and we will send you a code to
              reset your password!
            </p>
            <Form
              style={{ width: "55%", textAlign: "start" }}
              onSubmit={forgetFormik.handleSubmit}
            >
              <div className="mb-3">
                <Form.Label htmlFor="email">Your Email</Form.Label>
                <InputGroup id="email">
                  <Form.Control
                    // required
                    autoFocus
                    type="email"
                    name="email"
                    label="Email"
                    value={forgetFormik.values.email}
                    onChange={(e) => {
                      forgetFormik.setFieldValue("email", e.target.value);
                    }}
                    placeholder="john@company.com"
                  />
                </InputGroup>
                {forgetFormik.touched.email && forgetFormik.errors.email ? (
                  <div style={{ color: "red" }}>
                    {forgetFormik.errors.email}
                  </div>
                ) : null}
              </div>
              <p className="d-flex justify-content-end my-3">
                <Card.Link
                  as={Link}
                  to={Routers.Signin.path}
                  className="small text-end"
                >
                  Sign In
                </Card.Link>
              </p>

              <Card.Link
                // as={Link}
                className="text-gray-700"
              >
                <Button variant="primary" type="submit" className="w-100">
                  Recover password
                </Button>
              </Card.Link>
            </Form>
          </div>
        </Col>
      </Row>
      {/* </Container> */}
      {/* </section> */}
    </main>
  );
};
export default ForgetPage;
