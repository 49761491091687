import { toast } from 'react-toastify'
import { all, fork, put, select, takeLatest, call } from 'redux-saga/effects'
import axios from '../../Routes/axiosConfig'
import { sagaErrorHandler } from '../../Shared/shared'
import { makeSelectAuthToken } from '../../Store/selector'
import { logoutRequest } from '../auth/actions'
import {
  getUserNotificationSuccess,
  getONNotification,
  getONNotificationSuccess,
  getCardDetails,
  getCardDetailsSuccess,
  getUpgradeBusinessSuccess,
  getSwitchAccountSuccess,
  getSwitchAccount,
  getCardCheckSuccess,
  getWalletInfoSuccess,
  transferPaymentSuccess,
  toggleSchedulerSuccess,
  schedulePaymentSuccess
} from './actions'
import {
  GET_USER_NOTIFICATION,
  ON_NOTIFICATION,
  ADD_CARD_DETAILS,
  SELECT_CARD,
  GET_CARD_CHECK,
  DELETE_CARD,
  GET_CARD_DETAILS,
  ACCOUNT_PROVIDER,
  GET_BUSINESS_SEEKER,
  SWITCH_ACCOUNT,
  GET_WALLET_INFO,
  TRANSFER_PAYMENT,
  TOGGLE_SCHEDULER,
  SCHEDULE_PAYMENT,
} from './constants'
import images from '../../assets/img/noData.png'
import { CapitalizeFirstLetter } from '../../utils/Global'

function* NotificationSaga ({ payload }) {
  try {
    const headers = {
      headers: { authorization: yield select(makeSelectAuthToken()) }
    }
    const token = yield select(makeSelectAuthToken())
    const response = yield axios.get(
      `job/getNotificationKey/${payload.userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    payload.setLoader(false)
    yield put(getUserNotificationSuccess(response.data))
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(logoutRequest())
    }
    yield sagaErrorHandler(error.response)
  }
}
function* onNotificationSaga ({ payload }) {
  try {
    let data = {
      userId: payload.userId,
      isShowNotification: payload.isShowNotification
    }
    const token = yield select(makeSelectAuthToken())
    const response = yield axios.post(`job/isShowNotification`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    toast.success(CapitalizeFirstLetter(response.data.message))
    yield put(getONNotificationSuccess())
    // yield put(getUserNotification({
    //   userId: login.id,
    // }))
  } catch (error) {
    yield sagaErrorHandler(error.response)
  }
}
function* watchGetNotification () {
  yield takeLatest(GET_USER_NOTIFICATION, NotificationSaga)
}
function* watchOnNotification () {
  yield takeLatest(ON_NOTIFICATION, onNotificationSaga)
}
function* addCardSaga ({ payload }) {
  try {
    let data = {
      source: payload.token
    }
    payload.setLoader(true)
    const token = yield select(makeSelectAuthToken())
    const response = yield axios.post(`payment/addCard`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    toast.success(CapitalizeFirstLetter(response.data.message))
    yield put(getCardDetails(payload.userId))
    payload.setLoader(false)
    payload.setShowModal(false)
  } catch (error) {
    payload.setLoader(false)
    payload.setShowModal(false)
    yield sagaErrorHandler(error.response)
  }
}
function* watchAddCard () {
  yield takeLatest(ADD_CARD_DETAILS, addCardSaga)
}
function* selectCardSaga ({ payload }) {
  try {
    let data = {
      // source: payload.token,
      cardId: payload.card.id
    }
    payload.setLoader(true)
    const token = yield select(makeSelectAuthToken())
    const response = yield axios.post(`payment/selectCard`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    payload.setLoader(false)
    toast.success(CapitalizeFirstLetter(response.data.message))
    // yield put(getCardDetails(payload.userId));
  } catch (error) {
    payload.setLoader(false)
    yield sagaErrorHandler(error.response)
  }
}
function* watchSelectCard () {
  yield takeLatest(SELECT_CARD, selectCardSaga)
}
function* deleteCardSaga ({ payload }) {
  try {
    console.log('deleteCardSaga------>', payload)
    // let Data = new FormData();
    // Data.append("cardId", payload.id ? payload.id : null);
    // let data = {
    // source: payload.token,
    //   cardId: payload.id,
    // };
    payload.setLoader(true)
    const token = yield select(makeSelectAuthToken())
    const response = yield axios.delete(`payment/deleteCard`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: { cardId: payload.card.id }
    })
    toast.success(CapitalizeFirstLetter(response.data.message))
    // yield put(getCardDetails(payload.userId));
    // payload.setShowModal(false)
    payload.setLoader(false)
  } catch (error) {
    payload.setLoader(false)
    yield sagaErrorHandler(error.response)
  }
}
function* watchDeleteCard () {
  yield takeLatest(DELETE_CARD, deleteCardSaga)
}
function* getCardCheckSaga ({ payload }) {
  payload.setLoader(true)
  try {
    const token = yield select(makeSelectAuthToken())
    const response = yield axios.get(
      `payment/provider/checkCard/${payload.userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    yield put(getCardCheckSuccess(response.data.data))
    payload.setLoader(false)
  } catch (error) {
    payload.setLoader(false)
    if (error?.response?.status == 401) {
      yield put(logoutRequest())
    }
    yield sagaErrorHandler(error.response)
  }
}
function* watchCardCheck () {
  yield takeLatest(GET_CARD_CHECK, getCardCheckSaga)
}
function* accountProviderSaga ({ payload }) {
  try {
    let data = {
      userId: payload.userId
    }
    payload.setLoader(true)
    const token = yield select(makeSelectAuthToken())
    const response = yield axios.post(`payment/account/provider`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    console.log('response account provider ---->', response)
    payload.setLoader(false)
    yield call(() => {
      window.location.href = response.data.data
    })
    toast.success(CapitalizeFirstLetter(response.data.message))
  } catch (error) {
    payload.setLoader(false)
    yield sagaErrorHandler(error.response)
  }
}
function* watchAccountProvider () {
  yield takeLatest(ACCOUNT_PROVIDER, accountProviderSaga)
}
function* getCardSaga ({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken())
    const response = yield axios.get(`payment/cardInfo/${payload}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    yield put(getCardDetailsSuccess(response.data.data))
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(logoutRequest())
    }
    yield sagaErrorHandler(error.response)
  }
}
function* watchGetCard () {
  yield takeLatest(GET_CARD_DETAILS, getCardSaga)
}
function* UpgradeProfileSaga ({ payload }) {
  try {
    const headers = {
      headers: { authorization: yield select(makeSelectAuthToken()) }
    }
    const token = yield select(makeSelectAuthToken())
    const response = yield axios.patch(
      `Profile/become/business-seeker/${payload.userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    payload.setLoader(false)
    toast.success(CapitalizeFirstLetter(response.data.message))
    yield put(getUpgradeBusinessSuccess(response.data))
    yield put(
      getSwitchAccount({
        userId: payload.userId,
        role: 'business-seeker'
      })
    )
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(logoutRequest())
    }
    yield sagaErrorHandler(error.response)
  }
}
function* watchUpgradeProfile () {
  yield takeLatest(GET_BUSINESS_SEEKER, UpgradeProfileSaga)
}
function* SwitchSaga ({ payload }) {
  try {
    let data = {
      userId: payload.userId,
      userRole: 'business-seeker'
    }
    const headers = {
      headers: { authorization: yield select(makeSelectAuthToken()) }
    }
    const token = yield select(makeSelectAuthToken())
    const response = yield axios.post(`profile/switchAccount`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    payload.setLoader(false)
    toast.success(CapitalizeFirstLetter(response.data.message))
    yield put(getSwitchAccountSuccess(response.data))
  } catch (error) {
    if (error?.response?.status == 401) {
      yield put(logoutRequest())
    }
    yield sagaErrorHandler(error.response)
  }
}
function* watchSwitchSaga () {
  yield takeLatest(SWITCH_ACCOUNT, SwitchSaga)
}
function* getWalletInfoSaga({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(`payment/walletinfo/${payload}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(getWalletInfoSuccess(response.data.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(logoutRequest());
    }
    yield sagaErrorHandler(error.response);
  }
}

function* watchGetWalletInfo() {
  yield takeLatest(GET_WALLET_INFO, getWalletInfoSaga);
}

function* transferPaymentSaga({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`payment/transfer`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    payload.setLoader(false)
    yield put(transferPaymentSuccess(response.data));
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}

function* watchTransferPayment() {
  yield takeLatest(TRANSFER_PAYMENT, transferPaymentSaga);
}

function* toggleSchedulerSaga({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`payment/schedulerOnOff`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(toggleSchedulerSuccess(response.data));
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}

function* watchToggleScheduler() {
  yield takeLatest(TOGGLE_SCHEDULER, toggleSchedulerSaga);
}

function* schedulePaymentSaga({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`payment/scheduler`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(schedulePaymentSuccess(response.data));
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}

function* watchSchedulePayment() {
  yield takeLatest(SCHEDULE_PAYMENT, schedulePaymentSaga);
}


export default function* PushNotificationSaga () {
  yield all([fork(watchGetNotification)])
  yield all([fork(watchOnNotification)])
  yield all([fork(watchAddCard)])
  yield all([fork(watchSelectCard)])
  yield all([fork(watchAccountProvider)])
  yield all([fork(watchDeleteCard)])
  yield all([fork(watchCardCheck)])
  yield all([fork(watchGetCard)])
  yield all([fork(watchUpgradeProfile)])
  yield all([fork(watchSwitchSaga)])
  yield all([fork(watchGetWalletInfo)])
  yield all([fork(watchTransferPayment)])
  yield all([fork(watchToggleScheduler)])
  yield all([fork(watchSchedulePayment)])
}
