import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Nav, Pagination, Row } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import Spinner from "../../components/spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import CommonCard from "../../components/CommonCard";
import Navbar from "../../components/Navbar";
import NoRecordFound from "../../components/NoRecordFound";
import { markAsFavouriteJob } from "../../Redux/addJob/actions";
import { newArrival } from "../../Redux/Dashboard/actions";
import Profile from "../../assets/img/team/profile.png";

const DashboardOverview = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [newArrivalProvider, setNewArrivalProvider] = useState([]);
  const auth = useSelector((state) => state.auth.Auther);
  const totalPages = useSelector((state) => state?.Seeker?.newArrival?.pages) || 0;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const newArrivalData = useSelector((state) => state?.Seeker?.newArrival?.data) || [];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setNewArrivalProvider(newArrivalData);
  }, [newArrivalData]);

  useEffect(() => {
    dispatch(newArrival({ page, userId: auth?.id, count: 15, setLoader, search:"" }));
  }, [dispatch, page, auth?.id]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleClick = (id, index, isFavourite) => {
    // Optimistically update the local state
    const updatedProvider = newArrivalProvider.map((item, idx) =>
      idx === index ? { ...item, isFavourite: !isFavourite } : item
    );

    // Update the local state immediately
    setNewArrivalProvider(updatedProvider);

    // Dispatch the action to update the favorite status in the backend
    dispatch(markAsFavouriteJob({ id, setLoader }));
  };

  return (
    <>
      <Navbar module={"New Arrival Seekers"} />
      <Row className="py-2">
        {loader ? (
          <Spinner />
        ) : (
          <>
            {newArrivalProvider.length > 0 ? (
              <>
                {newArrivalProvider.map((item, index) => (
                  <Col key={item.id} lg={2} md={4} sm={6} xs={12} className="pb-3">
                    <CommonCard
                      img={item.images || Profile}
                      name={item.name}
                      type={item.employmentType}
                      id={item.id}
                      index={index}
                      isFavourite={item.isFavourite}
                      handleClick={handleClick}
                      rate={item.rate}
                      title={item.title}
                      completed={"90"}
                      star={item.rating}
                    />
                  </Col>
                ))}
                <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                  <Nav>
                    <Pagination size={"sm"} className="mb-2 mb-lg-0">
                      <Pagination.Prev onClick={() => handlePageChange(page - 1)}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                      </Pagination.Prev>
                      {[...Array(totalPages)].map((_, number) => (
                        <Pagination.Item
                          key={number + 1}
                          active={number + 1 === page}
                          onClick={() => handlePageChange(number + 1)}
                        >
                          {number + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next onClick={() => handlePageChange(page + 1)}>
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </Pagination.Next>
                    </Pagination>
                  </Nav>
                </Card.Footer>
              </>
            ) : (
              <NoRecordFound />
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default DashboardOverview;