export const GET_SEEKER_LISTING_SUCCESS= "GET_SEEKER_LISTING_SUCCESS";
export const  GET_SEEKER_LISTING = "GET_SEEKER_LISTING";
export const  NEW_ARRIVAL = "NEW_ARRIVAL";
export const  NEW_ARRIVAL_SUCCESS = "NEW_ARRIVAL_SUCCESS";
export const  TOP_RATED = "TOP_RATED";
export const  TOP_RATED_SUCCESS = "TOP_RATED_SUCCESS";
export const GET_CATEGORY_SUCCESS= "GET_CATEGORY_SUCCESS";
export const  GET_CATEGORY = "GET_CATEGORY";
export const GET_JOB_FILTER_SUCCESS= "GET_JOB_FILTER_SUCCESS";
export const  GET_JOB_FILTER = "GET_JOB_FILTER";    
export const BOOK_IT_AGAIN = "BOOK_IT_AGAIN";
export const BOOK_IT_AGAIN_SUCCESS = "BOOK_IT_AGAIN_SUCCESS"