import React, { useState } from "react";
import { Col, Row, Tabs, Tab } from "@themesberg/react-bootstrap";
import WorkerForm from './WorkerForm';
import CustomerForm from './CustomerForm';
import AuthLeft from "../../components/auth/AuthLeftSection.js";

const CreateProfile = () => {
  const [key, setKey] = useState("customer");

  return (
    <main>
      <Row className="justify-content-center h-100">
        <AuthLeft />
        <Col
          xs={11}
          md={7}
          className="mt-3 align-items-center justify-content-center h-100"
        >
          <div className="mb-5">
            <div className="text-center m-3">
              <Row className="align-items-center">
                <Col xs={12}>
                  <h3 className="mb-0">Sign in</h3>
                </Col>
              </Row>
            </div>
            <div
              style={{
                position: "relative",
                right: "5%",
                width: "105%",
                zIndex: "-1",
                borderBottom: "2px solid #E7F5FF",
              }}
            ></div>
            <div
              style={{
                width: "95%",
                height: "85vh",
                alignContent: "center",
                overflowY: "auto",
                textAlign: "-webkit-center",
              }}
            >
              <Tabs
                id="form-tabs"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="customer" title="Customer">
                  {key === 'customer' && <CustomerForm />}
                </Tab>
                <Tab eventKey="worker" title="Worker">
                  {key === 'worker' && <WorkerForm />}
                </Tab>
              </Tabs>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default CreateProfile;
