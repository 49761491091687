
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartLine, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import { ProviderCard, BusinessSeeker, PersonalSeeker, TotalJobs, TotalProviders, TotalSeekers, RevenueCard, CircleChartWidget, BarChartWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../DashBoardComponents/Widgets";
// import { PageVisitsTable } from "../../DashBoardComponents/Tables";
import { trafficShares, totalOrders } from "../../DashBoardComponents/DashBoardChart";
import Navbar from "../../components/Navbar";
import { getAdminUsers, getAdminCharts } from "../../Redux/AdminDashBoard/actions"
import SalesCard from './SummaryCard/SalesCard.jsx';
import CustomersCard from './SummaryCard/CustomersCard.jsx';
import Conventions from './SummaryCard/Conventions.jsx';
import InvoiceChart from './Charts/InvoiceChart';
import RevenueByLocationChart from './Charts/RevenueByLocationChart';
import DetailsTableCard from './Table/DetailsTable.jsx';

const AdminDashBoard = () => {
  const dispatch = useDispatch();
  const UsersCount = useSelector(
    (state) => state?.DashboardUsersCount?.UsersCounts
  );
  const MonthCount = useSelector(
    (state) => state?.DashboardUsersCount?.UsersChartMonths
  );
  useEffect(() => {
    dispatch(
      getAdminUsers()
    );
  }, []);

  useEffect(() => {
    dispatch(
      getAdminCharts()
    );
  }, []);
  return (
    <>
      <Navbar module={"Dashboard"} />
      {/* <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <ProviderCard
            category="Provider"
            // title="12"
            title={UsersCount?.provider}
            icon={faChartLine}
            iconColor="shape-secondary"
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <BusinessSeeker
            category="Business Seeker"
            // title="30"
            title={UsersCount?.bussinese_seeker}
            icon={faChartLine}
            iconColor="shape-secondary"
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <PersonalSeeker
            category="Seeker"
            // title="21"
            title={UsersCount?.seeker}
            icon={faChartLine}
            iconColor="shape-secondary"
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <TotalSeekers
            category="Total Seekers"
            // title="93"
            title={UsersCount?.seeker}
            icon={faChartLine}
            iconColor="shape-secondary"
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <RevenueCard
            category="Revenue"
            // title="50111"
            title={`$ ${UsersCount?.Revenue}`}
            period="Feb 1 - Apr 1"
            percentage={28.4}
            icon={faCashRegister}
            iconColor="shape-tertiary"
          />
        </Col>
      </Row> */}
      {/* Todo need to add endpoint */}
      <div className="justify-content-md-center row">
      <div className="mb-4 p-2 col-xl-4 col-lg-6 col-12">
        <SalesCard />
      </div>
      <div className="mb-4 p-2 col-xl-4 col-lg-6 col-12">
        <CustomersCard />
      </div>
      <div className="mb-4 p-2 col-xl-4 col-lg-6 col-12">
        <Conventions />
      </div>
    </div>
    <div className="justify-content-md-center row">
      <div className="mb-4 p-2 col-xl-8 col-12">
        <InvoiceChart />
      </div>
      <div className="mb-4 p-2 col-xl-4 col-12">
        <RevenueByLocationChart />
      </div>
      <div className="mb-4 p-2 col-12">
        <DetailsTableCard />
      </div>
    </div>
    </>
  );
};


export default AdminDashBoard;