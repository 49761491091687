
export const GET_USER_NOTIFICATION = "get_USER_NOTIFICATION";
export const GET_USER_NOTIFICATION_SUCCESS = "get_USER_NOTIFICATION_SUCCESS";
export const ON_NOTIFICATION = "ON_NOTIFICATION";
export const ON_NOTIFICATION_SUCCESS = "ON_NOTIFICATION_success";
export const ADD_CARD_DETAILS = "ADD_CARD_DETAILS";
export const SELECT_CARD = "SELECT_CARD";
export const DELETE_CARD = "DELETE_CARD";
export const GET_CARD_DETAILS = "GET_CARD_DETAILS";
export const GET_CARD_DETAILS_SUCCESS = "GET_CARD_DETAILS_SUCCESS";
export const GET_CARD_CHECK = "GET_CARD_CHECK";
export const GET_CARD_CHECK_SUCCESS = "GET_CARD_CHECK_SUCCESS";
export const ACCOUNT_PROVIDER = "ACCOUNT_PROVIDER";
export const GET_BUSINESS_SEEKER = "get_BUSINESS_SEEKER";
export const GET_BUSINESS_SEEKER_SUCCESS = "get_BUSINESS_SEEKER_SUCCESS";
export const SWITCH_ACCOUNT = "SWITCH_ACCOUNT";
export const SWITCH_ACCOUNT_SUCCESS = "SWITCH_ACCOUNT_SUCCESS";
export const GET_WALLET_INFO = "GET_WALLET_INFO";
export const GET_WALLET_INFO_SUCCESS = "GET_WALLET_INFO_SUCCESS";
export const TRANSFER_PAYMENT = "TRANSFER_PAYMENT";
export const TRANSFER_PAYMENT_SUCCESS = "TRANSFER_PAYMENT_SUCCESS";
export const TOGGLE_SCHEDULER = "TOGGLE_SCHEDULER";
export const TOGGLE_SCHEDULER_SUCCESS = "TOGGLE_SCHEDULER_SUCCESS";
export const SCHEDULE_PAYMENT = "SCHEDULE_PAYMENT";
export const SCHEDULE_PAYMENT_SUCCESS = "SCHEDULE_PAYMENT_SUCCESS";