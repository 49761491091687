import React from 'react';
import './Card.css';
import graphSvg from "./Graph.svg";

const SalesCard = () => {
  return (
    <div className="summary-card">
      <div className="icon">
      <img src={graphSvg} alt="" />
      </div>
      <div className="details">
        <div className="item">
          <div className="label">Sales</div>
          <div className="value">$4,000,000.00</div>
        </div>
        <div className="item">
          <div className="label">Volume</div>
          <div className="value d-flex align-items-center">450 <div className="ms-2 change">+20.00%</div> </div>
        </div>
      </div>
    </div>
  );
};

export default SalesCard;
