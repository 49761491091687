import React from "react";
import "./Card.css";
import userSvg from "./Two-User.svg";

const CustomersCard = () => {
  return (
    <div className="summary-card">
      <div className="icon">
        <img src={userSvg} alt="" />
      </div>
      <div className="details">
        <div className="item">
          <div className="label">Customers</div>
          <div className="value d-flex align-items-center">
            1,250 <div className="ms-2 change">+15.80%</div>{" "}
          </div>
        </div>
        <div className="item">
          <div className="label">Active</div>
          <div className="value d-flex align-items-center">
            1,180 <div className="ms-2 change">+85%</div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersCard;
