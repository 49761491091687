export const GET_JOB_LISTING = "get_Job_Listing";
export const GET_JOB_LISTING_SUCCESS = "get_Job_Listing_Success";
export const GET_JOB_PROFILE = "get_Job_Profile";
export const GET_JOB_PROFILE_SUCCESS = "get_Job_Profile_Success";
export const CHANGE_USERS_ACTIVE_PAGE = "change_users_active_page";
export const DELETE_JOB = "Delete_Job";
export const GET_CATEGORY_JOB = "get_Category_Job";
export const GET_CATEGORY_JOB_SUCCESS = "get_Category_Job_Success";
export const ACTION_JOB = "ACTION_JOB";
export const GET_JOB_EVENTS = "get_Job_Events";
export const GET_JOB_EVENTS_SUCCESS = "get_Job_Events_Success";