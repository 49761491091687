// eslint-disable-next-line
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import { all, fork, put, select, takeLatest } from "redux-saga/effects";
import axios from "../../Routes/axiosConfig";
import { sagaErrorHandler } from "../../Shared/shared";
import { makeSelectAuthToken } from "../../Store/selector";
import { Constant } from "../../utils/constant";
import {
  loginRequestSuccess,
  ContactUs,
  resetPasswordSuccess,
  signUpRequestSuccess,
  googleSignUpRequestSuccess,
  verifyOTPRequestSuccess,
  logoutRequestSuccess,
} from "./actions";
import {
  FORGOT_PASSWORD,
  LOGIN,
  LOGIN_FACEBOOK,
  LOGIN_GOOGLE,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  LOGOUT,
  CONTACT_US,
  SIGN_UP,
  GOOGLE_SIGN_UP,
  RESEND_OTP,
  VERIFY_OTP,
} from "./constants";
import { Routers } from "../../routes";

function* loginRequestSaga({ payload }) {
  payload.setLoader(true);
  let data = {
    email: payload.email,
    password: payload.password,
    webFcmToken: payload.webFcmToken,
  };
  try {
    const response = yield axios.post(`user/web/login`, data);
    localStorage.setItem("Token", response.data.data.access_token);
    toast.success("Login Successfully");
    yield put(loginRequestSuccess(response.data.data));
    payload.setLoader(false);
    let path =
      response.data.data?.user?.role?.name === Constant.Role.ADMIN
        ? Routers.AdminDashBoard.path
        : response.data.data?.user?.isEmailVerified &&
          response.data.data?.user?.isCompleteProfile &&
          response.data.data?.user?.isApproved
        ? "/"
        : response.data.data?.user?.isEmailVerified &&
          !response.data.data?.user?.isApproved
        ? Routers.CreateProfile.path
        : Routers.Verify_OTP.path;
    payload.history(path);
    payload.resetForm();
  } catch (error) {
    payload.setLoader(false);
    yield sagaErrorHandler(error.response);
  }
}

function* watchLogin() {
  yield takeLatest(LOGIN, loginRequestSaga);
}

function* signUpRequestSaga({ payload }) {
  let data = {
    email: payload.email,
    password: payload.password,
    fullName: payload.fullName,
    phoneNumber: payload.phoneNumber,
  };
  try {
    payload.setLoader(true);
    const response = yield axios.post(`user/register`, data);
    payload.setLoader(false);
    toast.success(response.data.message);
    response.data.status === 200 && payload.history(Routers.Verify_OTP.path);
    yield put(signUpRequestSuccess(data));
    payload.resetForm();
  } catch (error) {
    payload.setLoader(false);
    yield sagaErrorHandler(error.response);
  }
}

function* watchSignUp() {
  yield takeLatest(SIGN_UP, signUpRequestSaga);
}

function* logoutRequestSaga({ payload }) {
  let { user } = payload;
  try {
    if (user.role.name !== Constant.Role.ADMIN) {
      yield axios.post(`user/logout/${user.id}`);
    }
    // yield put(logoutRequestSuccess());
    yield put(loginRequestSuccess(null));

    payload.setLoader(false);
    toast.success("Logout Successfully.");
    payload.resetForm();
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchLogout() {
  yield takeLatest(LOGOUT, logoutRequestSaga);
}

function* contactUsSaga({ payload }) {
  try {
    yield axios.post(`user/contact-us`, payload);

    payload.setLoader(false);
    toast.success("Email Send Successfully");
    payload.resetForm();
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchContactUs() {
  yield takeLatest(CONTACT_US, contactUsSaga);
}

function* forgetRequestSaga({ payload }) {
  let data = {
    email: payload.email,
  };
  try {
    yield axios.post(`/user/forgot-password`, data);

    yield put(resetPasswordSuccess(payload.email));
    toast.success("Email sent successfully");
    // yield put(push("/forget-password"));
    payload.history(Routers.Verify_OTP.path);
    payload.setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchForget() {
  yield takeLatest(FORGOT_PASSWORD, forgetRequestSaga);
}
function* resetRequestSaga({ payload }) {
  let data = {
    password: payload.confirmPassword,
    email: payload?.email,
    // token: payload?.token,
  };
  try {
    yield axios.post(`user/reset-password`, data);
    toast.success("Password Reset Successfully");
    // toast.success("Password is old.Kindly enter new password ");
    // yield put(logoutRequestSuccess());
    payload.history("/");
    payload.setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}

function* watchReset() {
  yield takeLatest(RESET_PASSWORD, resetRequestSaga);
}

function* updatePasswordSaga({ payload }) {
  const token = yield select(makeSelectAuthToken());
  let data = {
    email: payload.email,
    oldPassword: payload.currentpassword,
    newPassword: payload.newpassword,
  };
  try {
    const response = yield axios.post(`user/update-password`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(response.data.message);
    // yield put(updatePasswordSuccess(response.data.data));
    payload.setShowDefault(false);
    payload.reset();
    payload.setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchUpdatePassword() {
  yield takeLatest(UPDATE_PASSWORD, updatePasswordSaga);
}

function* LoginFacebookSaga({ payload }) {
  // const token = yield select(makeSelectAuthToken());
  let data = {
    email: payload.email,
    web: true,
  };
  try {
    const response = yield axios.post(`facebook-authentication`, data);
    localStorage.setItem("Token", response.data.data.access_token);
    toast.success(response.data.message);
    // payload.setLoader(false);
    yield put(loginRequestSuccess(response.data.data));
    // payload.setLoader(false);
    let path =
      response.data.data.user.userRole === Constant.Role.ADMIN
        ? "/adminDashBoard"
        : response.data.data.user.userRole === Constant.Role.SUB_ADMIN
        ? "/user_management"
        : "/dashboard";
    payload.history(path);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchFacebookLogin() {
  yield takeLatest(LOGIN_FACEBOOK, LoginFacebookSaga);
}
function* LogingoogleSaga({ payload }) {
  let data = {
    email: payload.email,
    fcmToken: payload.fcmToken,
  };
  try {
    const response = yield axios.post(`user/signInWithSocialWeb`, data);
    localStorage.setItem("Token", response.data.data.access_token);
    toast.success(response.data.message);
    yield put(loginRequestSuccess(response.data.data));
    let path =
      response.data.data?.user?.role?.name === Constant.Role.ADMIN
        ? Routers.AdminDashBoard.path
        : response.data.data?.user?.isEmailVerified &&
          response.data.data?.user?.isCompleteProfile
        ? "/"
        : response.data.data?.user?.isEmailVerified
        ? Routers.CreateProfile.path
        : Routers.Verify_OTP.path;
    payload.history(path);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}

function* watchGoogleLogin() {
  yield takeLatest(LOGIN_GOOGLE, LogingoogleSaga);
}

function* SignUpGoogleSaga({ payload }) {
  let data = {
    email: payload.email,
    fullName: payload.name,
    socialKey: payload.socialKey,
  };
  try {
    const response = yield axios.post(`user/registerWithSocial`, data);
    localStorage.setItem("Token", response.data.data.access_token);
    toast.success(response.data.message);
    payload.setLoader(true);
    yield put(googleSignUpRequestSuccess(response.data.data));
    payload.setLoader(false);
    let path =
      response.data.data?.user?.role?.name === Constant.Role.ADMIN
        ? Routers.AdminDashBoard.path
        : response.data.data?.user?.isEmailVerified &&
          response.data.data?.user?.isCompleteProfile
        ? "/"
        : response.data.data?.user?.isEmailVerified
        ? Routers.CreateProfile.path
        : Routers.Verify_OTP.path;
    payload.history(path);
  } catch (error) {
    payload.setLoader(false);
    yield sagaErrorHandler(error.response);
  }
}

function* watchGoogleSignUp() {
  yield takeLatest(GOOGLE_SIGN_UP, SignUpGoogleSaga);
}

function* resendOTpRequestSaga({ payload }) {
  let data = {
    email: payload.email,
  };
  const userAuth = yield select((state) => state.auth);
  try {
    if (!userAuth?.isUserForgotPassword) {
      payload.setLoader(true);
      const response = yield axios.post(`user/resendCode`, data);

      toast.success(response.data.message);
      payload.setLoader(false);
    } else {
      const response = yield axios.post(`user/forget-pwd/resendCode`, data);

      toast.success(response.data.message);
    }
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchResendOTP() {
  yield takeLatest(RESEND_OTP, resendOTpRequestSaga);
}

function* verifyOTpRequestSaga({ payload }) {
  let data = {
    email: payload.email,
    code: payload.code,
  };
  const userAuth = yield select((state) => state.auth);

  try {
    if (!userAuth?.isUserForgotPassword) {
      payload.setLoader(true);
      const response = yield axios.post(`user/verifyCode`, data);

      toast.success(response.data.message);
      yield put(verifyOTPRequestSuccess(response.data?.data));
      if (
        response.data.data?.data?.isCompleteProfile &&
        response.data?.data?.access_token
      )
        payload.history("/");
      else payload.history(Routers.CreateProfile.path);
      payload.setLoader(false);
    } else {
      const response = yield axios.post(`user/verify/forgot-pwd`, data);

      toast.success(response.data.message);

      yield put(verifyOTPRequestSuccess(userAuth));
      payload.history(Routers.ResetPassword.path);
      // if(response.data.data?.isCompleteProfile && response.data.access_token) payload.history("/");
      // else payload.history(Routers.CreateProfile.path);
    }
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchVerifyOTP() {
  yield takeLatest(VERIFY_OTP, verifyOTpRequestSaga);
}

export default function* AuthSaga() {
  yield all([
    fork(watchLogin),
    fork(watchForget),
    fork(watchContactUs),
    fork(watchReset),
    fork(watchUpdatePassword),
    fork(watchFacebookLogin),
    fork(watchGoogleLogin),
    fork(watchGoogleSignUp),
    fork(watchLogout),
    fork(watchSignUp),
    fork(watchResendOTP),
    fork(watchVerifyOTP),
  ]);
}
