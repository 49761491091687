import React from 'react';
import { FaEnvelope, FaCalendarAlt } from 'react-icons/fa';
import './DetailsTable.css';

const invoiceData = [
  {
    invoice: '#4399201',
    name: 'Cynthia Robinson',
    email: 'lorem.ipsum@gmail.com',
    date: 'Mar 18, 2022 22:50',
    status: 'Canceled',
  },
  {
    invoice: '#9833251',
    name: 'Sonia Hall',
    email: 'alex.page@hotmail.com',
    date: 'Mar 15, 2022 00:07',
    status: 'Completed',
  },
  {
    invoice: '#6093576',
    name: 'Scott Chambers',
    email: 'willie.bennett@aol.com',
    date: 'Mar 10, 2022 01:06',
    status: 'Due',
  },
  {
    invoice: '#3325871',
    name: 'Jimmy Mann',
    email: 'c.mann@hotmail.com',
    date: 'Mar 09, 2022 02:37',
    status: 'Completed',
  },
];

const statusStyles = {
  Canceled: { backgroundColor: '#f8d7da', color: '#721c24' },
  Completed: { backgroundColor: '#d4edda', color: '#155724' },
  Due: { backgroundColor: '#fff3cd', color: '#856404' },
};

const DetailsTable = () => {
  return (
    <div className="details-container">
      <div className="details-header">
        <h2>Details</h2>
        <table className="details-table">
          <thead>
            <tr>
              <th>Invoice</th>
              <th>Name</th>
              <th>Email</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.map((item, index) => (
              <tr key={index}>
                <td>
                  <a href="#" style={{ color: '#007bff', textDecoration: 'none' }}>
                    {item.invoice}
                  </a>
                </td>
                <td>{item.name}</td>
                <td>
                  <a href={`mailto:${item.email}`} style={{ color: '#007bff', textDecoration: 'none' }}>
                    <FaEnvelope /> {item.email}
                  </a>
                </td>
                <td>
                  <FaCalendarAlt /> {item.date}
                </td>
                <td>
                  <span style={{ ...statusStyles[item.status], padding: '5px 10px', borderRadius: '5px' }}>
                    {item.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailsTable;
