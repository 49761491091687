import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Modal,
} from "@themesberg/react-bootstrap";
import moment from "moment";

const JobExperienceModal = ({ onClose, onSubmit, isUpdateMode, initialFormData }) => {
  const [formData, setFormData] = useState(initialFormData || {
    jobTitle: '',
    employement: '',
    location: '',
    startDate: '',
    endDate: '',
    details: ''
  });

  useEffect(() => {
    if (initialFormData && isUpdateMode) {
      setFormData(initialFormData);
    }
  }, [initialFormData, isUpdateMode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const modalTitle = isUpdateMode ? 'Update Job Experience' : 'Add Job Experience';

  const handleClose = () => {
    onClose();
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    onClose();
  };

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Job Title</Form.Label>
            <Form.Control
              type="text"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Employment Type</Form.Label>
            <Form.Control
              as="select"
              name="employement"
              value={formData.employement}
              onChange={handleChange}
            >
              <option value="">Select Employment Type</option>
              <option value="full-time">Full Time</option>
              <option value="part-time">Part Time</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              name="startDate"
              value={moment(formData.startDate).format("YYYY-MM-DD") || ""}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              name="endDate"
              value={moment(formData.endDate).format("YYYY-MM-DD") || ""}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Experience Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="details"
              value={formData.details}
              onChange={handleChange}
            />
          </Form.Group>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            {isUpdateMode ? 'Update' : 'Add'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default JobExperienceModal;
