import React from "react";
import "./Card.css";
import bagSvg from "./Bag.svg";

const Conventions = () => {
  return (
    <div className="summary-card">
      <div className="icon">
        <img src={bagSvg} alt="" />
      </div>
      <div className="details">
        <div className="item">
          <div className="label">Conventions</div>
          <div className="value d-flex align-items-center">450</div>
        </div>
        <div className="item">
          <div className="label">Pending</div>
          <div className="value d-flex align-items-center">5</div>
        </div>
        <div className="item">
          <div className="label">Completed</div>
          <div className="value d-flex align-items-center">445</div>
        </div>
      </div>
    </div>
  );
};

export default Conventions;
