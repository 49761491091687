import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getWalletInfo,
  transferPayment,
  toggleScheduler,
  schedulePayment,
} from '../../Redux/settings/actions';
import {
  Box,
  Button,
  CardContent,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Col, Card, Row } from '@themesberg/react-bootstrap';
import Navbar from '../../components/Navbar';

const Wallet = () => {
  const [manualWithdrawOpen, setManualWithdrawOpen] = useState(false);
  const [scheduledWithdrawOpen, setScheduledWithdrawOpen] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [scheduledDate, setScheduledDate] = useState('');
  const [schedulerOn, setSchedulerOn] = useState(false);
  const login = useSelector((state) => state.auth);
  const balance = useSelector((state) => state.PushNotification.walletInfo?.amount);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWalletInfo(login.Auther.id));
  }, [dispatch, login.Auther.id]);

  const handleManualWithdrawOpen = () => {
    setManualWithdrawOpen(true);
  };

  const handleManualWithdrawClose = () => {
    setManualWithdrawOpen(false);
  };

  const handleScheduledWithdrawOpen = () => {
    setScheduledWithdrawOpen(true);
  };

  const handleScheduledWithdrawClose = () => {
    setScheduledWithdrawOpen(false);
  };

  const handleManualWithdraw = () => {
    dispatch(
      transferPayment({
        amount: withdrawAmount,
        userId: login.Auther.id,
        setLoader: setManualWithdrawOpen,
      })
    );
  };

  const handleScheduledWithdraw = () => {
    dispatch(
      toggleScheduler({
        userId: login.Auther.id,
        onScheduler: schedulerOn,
      })
    ).then(() => {
      dispatch(
        schedulePayment({
          amount: withdrawAmount,
          date: scheduledDate,
          userId: login.Auther.id,
          setLoader: setScheduledWithdrawOpen,
        })
      );
    });
  };

  return (
    <>
      <Navbar module={'Payment Method'} />
      <Row>
        <Col xs={12}>
          <Card border='light' className='p-0 mb-4 profileView'>
            <Card.Header>
              <span>Wallet Details</span>
            </Card.Header>
            <Card.Body>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  backgroundColor: '#e3f2fd',
                  minHeight: '64vh',
                  paddingTop: '50px'
                }}
              >
                <Card
                  sx={{
                    backgroundColor: '#2196f3',
                    color: '#fff',
                    width: '300px',
                    borderRadius: '20px',
                    padding: '20px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                  }}
                >
                  <CardContent>
                    <Typography variant='h5' component='div'>
                      Balance
                    </Typography>
                    <Typography
                      variant='h3'
                      component='div'
                      sx={{ marginTop: '10px' }}
                    >
                      ${balance || 0}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Card.Body>
            <Card.Footer className='text-center'>
              <Button
                variant='contained'
                sx={{
                  marginTop: '20px',
                  backgroundColor: '#2196f3',
                  color: '#fff',
                  borderRadius: '20px',
                  width: '200px',
                  height: '50px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                }}
                onClick={handleManualWithdrawOpen}
              >
                Manual Withdraw
              </Button>
              <Button
                variant='contained'
                sx={{
                  marginTop: '20px',
                  marginLeft: '10px',
                  backgroundColor: '#2196f3',
                  color: '#fff',
                  borderRadius: '20px',
                  width: '200px',
                  height: '50px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                }}
                onClick={handleScheduledWithdrawOpen}
              >
                Schedule Withdraw
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      <Dialog open={manualWithdrawOpen} onClose={handleManualWithdrawClose}>
        <DialogTitle>Manual Withdraw</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='Withdraw Amount'
            type='number'
            fullWidth
            variant='standard'
            value={withdrawAmount}
            onChange={(e) => setWithdrawAmount(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleManualWithdrawClose}>Cancel</Button>
          <Button onClick={handleManualWithdraw}>Withdraw</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={scheduledWithdrawOpen} onClose={handleScheduledWithdrawClose}>
        <DialogTitle>Schedule Withdraw</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='Withdraw Amount'
            type='number'
            fullWidth
            variant='standard'
            value={withdrawAmount}
            onChange={(e) => setWithdrawAmount(e.target.value)}
          />
          <TextField
            margin='dense'
            label='Scheduled Date'
            type='date'
            fullWidth
            variant='standard'
            InputLabelProps={{
              shrink: true,
            }}
            value={scheduledDate}
            onChange={(e) => setScheduledDate(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox checked={schedulerOn} onChange={(e) => setSchedulerOn(e.target.checked)} />}
            label="Enable Scheduler"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleScheduledWithdrawClose}>Cancel</Button>
          <Button onClick={handleScheduledWithdraw}>Schedule</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Wallet;
