import { Button, Modal } from "@themesberg/react-bootstrap";
import { useDispatch } from "react-redux";
import { applyJob } from "../../../Redux/addJob/actions";

const ApplyModal = ({ setApply, apply,jobId, userId}) => {
  const dispatch = useDispatch();
  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={apply}
      onHide={() => {
        setApply(false);
      }}
    >
      <Modal.Header>
        <Modal.Title className="h5">{"Confirmation"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <Modal.Title className="h5 text-left">
          Are you sure you want to Apply on this job?
        </Modal.Title>

        <div class="d-flex justify-content-between mt-3">
          <Button
            variant="white shadow"
            color="dark"
            onClick={() => {
                setApply(false);
              }}
            type="button"
            size="sm"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            color="dark"
            type="button"
            onClick={() => {
              dispatch(applyJob({jobId:jobId, setApply:setApply}))
            }}
            size="sm"
          >
            Yes
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ApplyModal;
