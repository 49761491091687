import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import {
  getProfile,
  updateCompleteWorkerProfile,
} from "../../Redux/profile/actions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Row,
  Table,
} from "@themesberg/react-bootstrap";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JobExperienceModal from "./JobExperience/JobExperienceModal";
import AchievementsModal from "./Achievements/AchievementsModal";
import CategoryModal from "./CategorySelector/CategorySelectorModal"; // Import the CategoryModal component
import ReactPlayer from "react-player";
import Autocomplete from "react-google-autocomplete";

const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyBJWt1Yh6AufjxV8B8Y8UVz_25cYV1fvhs";
const UpdateWorkerProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useSelector((state) => state.auth.Auther);
  const getById = useSelector((state) => state.ProfileReducer.profile);
  const [isJobModalOpen, setIsJobModalOpen] = useState(false);
  const [isAchievementsModalOpen, setIsAchievementsModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false); // State for CategoryModal
  const [workExperience, setWorkExperience] = useState([]);

  const [location, setLocation] = useState(getById?.address || "");
  const [longitude, setLogintude] = useState(getById?.longitude);
  const [latitude, setLatitude] = useState(getById?.latitude);
  const [achievements, setAchievements] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedCertification, setSelectedCertification] = useState();
  const [selectedLicense, setSelectedLicense] = useState();
  const [editAchievementsIndex, setEditAchievementsIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    dateOfBirth: Yup.string().required("Date Of Birth is required"),
    address: Yup.string().required("Address is required"),
    postalCode: Yup.string().required("Postal Code is required"),
    phoneNumber: Yup.string().test(
      "isValidPhoneNumber",
      "Invalid phone number",
      (value) => value && isValidPhoneNumber(value)
    ),
    city: Yup.string().required("City is required"),
    province: Yup.string().required("Province is required"),
    transportationAvailable: Yup.string().required(
      "Transportation is required"
    ),
  });
  const handleOpenJobModal = () => {
    setEditIndex(null);
    setIsJobModalOpen(true);
  };

  const handleCloseJobModal = () => {
    setIsJobModalOpen(false);
  };

  const handleJobSubmit = (data) => {
    if (editIndex !== null) {
      const updatedworkExperience = [...workExperience];
      updatedworkExperience[editIndex] = data;
      setWorkExperience(updatedworkExperience);
    } else {
      setWorkExperience([...workExperience, data]);
    }
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setIsJobModalOpen(true);
  };
  const handleDelete = (index) => {
    const updatedworkExperience = workExperience.filter((_, i) => i !== index);
    setWorkExperience(updatedworkExperience);
  };

  const handleOpenAchievementsModal = () => {
    setEditAchievementsIndex(null);
    setIsAchievementsModalOpen(true);
  };

  const handleCloseAchievementsModal = () => {
    setIsAchievementsModalOpen(false);
  };

  const handleEditAchievements = (index) => {
    setEditAchievementsIndex(index);
    setIsAchievementsModalOpen(true);
  };
  const handleDeleteAchievements = (index) => {
    const updatedachievements = achievements.filter((_, i) => i !== index);
    setAchievements(updatedachievements);
  };

  const handleAchievementsSubmit = (data) => {
    if (editAchievementsIndex !== null) {
      const updatedachievements = [...achievements];
      updatedachievements[editAchievementsIndex] = data;
      setAchievements(updatedachievements);
    } else {
      setAchievements([...achievements, data]);
    }
    setEditAchievementsIndex(null);
  };
  const [selectedImage, setSelectedImage] = useState();
  const [selectedsocailInsuranceInfo, setSelectedsocailInsuranceInfo] =
    useState();
  const [selectedProfileType, setSelectedProfileType] = useState("full-time");
  const [videoURL, setVideoURL] = useState("");
  const handleChange = (selected) => {
    setSelectedProfileType(selected.value);
  };

  const options = [
    { value: "full-time", label: "Full Time" },
    { value: "part-time", label: "Part Time" },
  ];

  const [loader, setLoader] = useState(false);

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);

  // const [value, setValue] = useState();
  const { errors } = formState;
  const [dateofBirth] = React.useState(new Date());
  const [user, setUser] = useState();

  useEffect(() => {
    reset(user);
  }, [user, reset]);

  useEffect(() => {
    setUser({
      fullName: getById?.fullName || "",
      address: getById?.address || "",
      dateOfBirth: getById?.dateOfBirth
        ? moment(getById.dateOfBirth).format("YYYY-MM-DD")
        : "",
      phoneNumber: getById?.phoneNumber || "",
      city: getById?.city || "",
      province: getById?.province || "",
      postalCode: getById?.postalCode || "",
      profileImg: getById?.profileImg || "",
      profileType: getById?.profileType || "",
      socailInsuranceInfo: getById?.socailInsuranceInfo || "",
      certification: getById?.certification || "",
      license: getById?.license || "",
      workExperience: getById?.workExperience || [],
      achievements: getById?.achievements || [],
      transportationAvailable: getById?.transportationAvailable || [""],
      // languages: getById?.languages || [],
      videoUrl: getById?.videoUrl || "",
    });
    setSelectedCertification(getById?.certification ?? "");
    setSelectedLicense(getById?.license ?? "");
    setSelectedsocailInsuranceInfo(getById?.socailInsuranceInfo ?? "");
    setSelectedLanguages(getById?.language?.map((language) => language.name));
    setWorkExperience(getById.workExperience || []);
    setAchievements(getById.achievements || []);
    setVideoURL(getById?.videoUrl || "");
    setLogintude(getById?.longitude);
    setLatitude(getById?.latitude);
  }, [getById]);

  useEffect(() => {
    dispatch(
      getProfile({
        profileId: login?.id,
        setLoader: setLoader,
      })
    );
  }, [dispatch, login?.id, setLoader]);
  function onSubmit(data) {
    let valid = isValidPhoneNumber(data.phoneNumber);
    if (!valid) {
      return;
    } else {
      dispatch(
        updateCompleteWorkerProfile({
          fullName: data.fullName,
          address: location,
          dateofBirth: dateofBirth
            ? moment.utc(dateofBirth).format().toString()
            : getById?.dateofBirth.toString(),
          phoneNumber: data.phoneNumber
            ? data.phoneNumber
            : getById?.phoneNumber,
          city: data.city,
          postalCode: data.postalCode,
          province: data.province,
          latitude: latitude,
          longitude: longitude,
          transportationAvailable: data.transportationAvailable,
          id: getById.id,
          profileType: selectedProfileType
            ? selectedProfileType
            : getById?.profileType,
          profileImg: selectedImage ? selectedImage : getById?.profileImg,
          socailInsuranceInfo: selectedsocailInsuranceInfo
            ? selectedsocailInsuranceInfo
            : getById?.socailInsuranceInfo,
          certification: selectedCertification
            ? selectedCertification
            : getById?.certification,
          license: selectedLicense ? selectedLicense : getById?.license,
          workExperience: workExperience
            ? workExperience
            : getById?.workExperience,
          achievements: achievements ? achievements : getById?.achievements,
          accountType: "worker",
          history: navigate,
          setLoader: setLoader,
          languages: selectedLanguages,
          videoURL: videoURL,
        })
      );
    }
  }

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const videoUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setVideoURL(e.target.files[0]);
    }
  };

  const socailInsuranceInfoChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedsocailInsuranceInfo(e.target.files[0]);
    }
  };

  const certificationChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedCertification(e.target.files[0]);
    }
  };

  const licenseChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedLicense(e.target.files[0]);
    }
  };

  const inputEl = useRef(null);

  const onButtonClick = () => {
    inputEl.current.click();
  };

  useEffect(() => {
    // if (getById?.phoneNumber !== undefined) {
    //   setValue(getById?.phoneNumber);
    // }
    if (getById?.address !== undefined) {
      setLocation(getById?.address);
    }
  }, [getById]);

  const languageOptions = [
    { value: "english", label: "English" },
    { value: "spanish", label: "Spanish" },
    { value: "french", label: "French" },
    { value: "german", label: "German" },
  ];

  // const handleOpenCategoryModal = () => {
  //   setIsCategoryModalOpen(true);
  // };

  const handleCloseCategoryModal = () => {
    setIsCategoryModalOpen(false);
  };
  useEffect(() => {
    console.log(errors, "errors");
  }, [errors]);
  return (
    <>
      {user && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12}>
                  <Card
                    border="light"
                    className="text-center p-0 mb-4 profileView"
                    style={{ cursor: "pointer", height: "300px" }}
                  >
                    {selectedImage ? (
                      <Card.Img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Neil Portrait"
                        onClick={onButtonClick}
                        ref={inputEl}
                        className="user-avatar large-avatar rounded-circle mx-auto mt-5"
                      />
                    ) : (
                      <Card.Img
                        src={getById?.profileImg}
                        alt="Neil Portrait"
                        onClick={onButtonClick}
                        ref={inputEl}
                        className="user-avatar large-avatar rounded-circle mx-auto mt-5"
                      />
                    )}
                    <Form.Group className="col my-2">
                      <Form.Control
                        accept="image/*"
                        type="file"
                        id="file"
                        name="file"
                        onChange={imageChange}
                        className="d-none"
                        ref={inputEl}
                        style={{ cursor: "pointer" }}
                      />
                      <div className="invalid-feedback">
                        {errors.file?.message}
                      </div>
                    </Form.Group>
                    <Card.Body className="pb-5">
                      <Dropdown.Menu className="custom_menu">
                        <Dropdown.Item onClick>
                          <FontAwesomeIcon icon={faEdit} className="me-2" />{" "}
                          Edit
                        </Dropdown.Item>
                      </Dropdown.Menu>
                      <Card.Subtitle
                        className="text-gray mb-2"
                        onClick={onButtonClick}
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Profile image
                      </Card.Subtitle>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Card
                    border="light"
                    className="text-center p-0 mb-4 profileView"
                    style={{ cursor: "pointer", height: "300px" }}
                  >
                    <Form.Group className="col my-2">
                      <Form.Label>Video File</Form.Label>
                      <Form.Control
                        name="videoUrl"
                        type="file"
                        onChange={videoUpload}
                      />
                    </Form.Group>
                    {videoURL && (
                      <ReactPlayer
                        url={videoURL}
                        width="100%"
                        height="100%"
                        controls={true}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={12} xl={8}>
              <Card
                border="light"
                className="text-left p-0 mb-4 profileView info"
              >
                <Card.Body className="pb-3">
                  <Card.Title className="text-primary">
                    Basic Information
                  </Card.Title>

                  <Form.Group className="col my-2">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      name="fullName"
                      type="text"
                      {...register("fullName")}
                      className={`form-control ${
                        errors.fullName ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.fullName?.message}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Col lg={12} md={6} className="mb-1">
                      <Form.Label>Profile Type</Form.Label>
                      <Select
                        value={options.find(
                          (option) => option.value === selectedProfileType
                        )}
                        onChange={handleChange}
                        options={options}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Date Of Birth</Form.Label>
                    <Form.Control
                      name="dateOfBirth"
                      type="date"
                      {...register("dateOfBirth")}
                      defaultValue={
                        user?.dateOfBirth
                          ? new Date(user.dateOfBirth).toLocaleDateString(
                              "en-US"
                            )
                          : ""
                      } // Set default value for date in mm/dd/yyyy format
                      className={`form-control ${
                        errors.dateOfBirth ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.dateOfBirth?.message}
                    </div>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Phone</Form.Label>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      render={({ field }) => (
                        <PhoneInput
                          {...field}
                          error={
                            field.value
                              ? isValidPhoneNumber(field.value)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                        />
                      )}
                    />
                    <div className="invalid-phone">
                      {errors.phoneNumber && errors.phoneNumber.message}
                    </div>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Address</Form.Label>
                    <Controller
                      name="address"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                          style={{ width: "100%" }}
                          className={`form-control pac-target-input ${
                            errors.address ? "is-invalid" : ""
                          }`}
                          onPlaceSelected={(place) => {
                            setLocation(place.formatted_address);
                            setLogintude(place.geometry.location.lng());
                            setLatitude(place.geometry.location.lat());
                            field.onChange(place.formatted_address);
                          }}
                          value={location} // Ensure the value is controlled
                          onChange={(e) => {
                            setLocation(e.target.value);
                            field.onChange(e.target.value);
                          }} // Allow user input
                          defaultValue={getById?.address} // Set default value
                        />
                      )}
                    />
                    <div className="invalid-feedback">
                      {errors.address?.message}
                    </div>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                      name="postalCode"
                      type="text"
                      {...register("postalCode")}
                      className={`form-control ${
                        errors.postalCode ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.postalCode?.message}
                    </div>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      name="city"
                      type="text"
                      {...register("city")}
                      className={`form-control ${
                        errors.city ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.city?.message}
                    </div>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Province</Form.Label>
                    <Form.Control
                      name="province"
                      type="text"
                      {...register("province")}
                      className={`form-control ${
                        errors.province ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.province?.message}
                    </div>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Languages</Form.Label>
                    <Controller
                      name="languages"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isMulti
                          options={languageOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(selectedOptions) => {
                            setSelectedLanguages(
                              selectedOptions.map((option) => option.value)
                            ); // Save selected values in state
                          }}
                          value={selectedLanguages?.map((value) =>
                            languageOptions.find(
                              (option) => option.value === value
                            )
                          )} // Set selected values
                        />
                      )}
                    />
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Transportation Available</Form.Label>
                    <Form.Control
                      name="transportationAvailable"
                      type="text"
                      {...register("transportationAvailable")}
                      className={`form-control ${
                        errors.transportationAvailable ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.transportationAvailable?.message}
                    </div>
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Socail Insurance Info</Form.Label>
                    <Form.Control
                      name="socailInsuranceInfo"
                      type="file"
                      {...register("socailInsuranceInfo")}
                      onChange={socailInsuranceInfoChange}
                      className={`form-control ${
                        errors.socailInsuranceInfo ? "is-invalid" : ""
                      }`}
                    />
                    {selectedsocailInsuranceInfo && (
                      <div className="mt-2">
                        <a
                          href={selectedsocailInsuranceInfo}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Socail Insurance Info
                        </a>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>Certification</Form.Label>
                    <Form.Control
                      name="certification"
                      type="file"
                      {...register("certification")}
                      onChange={certificationChange}
                      className="form-control"
                    />
                    {selectedCertification && (
                      <div className="mt-2">
                        <a
                          href={selectedCertification}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Certificate
                        </a>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label>License</Form.Label>
                    <Form.Control
                      name="license"
                      type="file"
                      {...register("license")}
                      onChange={licenseChange}
                      className="form-control"
                    />
                    {selectedLicense && (
                      <div className="mt-2">
                        <a
                          href={selectedLicense}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View selectedLicense
                        </a>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="col my-2">
                    <Form.Label className="col-8">Work Experience</Form.Label>
                    <Button
                      className="col-4"
                      variant="primary"
                      color="dark"
                      size="sm"
                      onClick={handleOpenJobModal}
                    >
                      Add Work Experience
                    </Button>
                  </Form.Group>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Job Title</th>
                        <th>Employment Type</th>
                        <th>Location</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {workExperience.map((formData, index) => (
                        <tr key={index}>
                          <td>{formData.jobTitle}</td>
                          <td>{formData.employement}</td>
                          <td>{formData.location}</td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() => handleEdit(index)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => handleDelete(index)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Form.Group className="col my-2">
                    <Form.Label className="col-8">Achievements</Form.Label>
                    <Button
                      className="col-4"
                      variant="primary"
                      color="dark"
                      size="sm"
                      onClick={handleOpenAchievementsModal}
                    >
                      Add Achievements
                    </Button>
                  </Form.Group>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Details</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {achievements.map((formData, index) => (
                        <tr key={index}>
                          <td>{formData.title}</td>
                          <td>{formData.details}</td>
                          <td>
                            {moment(formData.startDate).format("YYYY-MM-DD")}
                          </td>
                          <td>
                            {moment(formData.endDate).format("YYYY-MM-DD")}
                          </td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() => handleEditAchievements(index)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => handleDeleteAchievements(index)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Form.Group>
                    <div className="d-grid gap-2 col-4 text-center mt-3 mx-auto">
                      <Button
                        variant="primary"
                        color="dark"
                        size="sm"
                        type="submit"
                        disabled={loader}
                      >
                        {loader ? " Loading..." : "Update Profile"}
                      </Button>
                    </div>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
      {isJobModalOpen && (
        <JobExperienceModal
          onClose={handleCloseJobModal}
          onSubmit={handleJobSubmit}
          isUpdateMode={editIndex !== null}
          initialFormData={workExperience[editIndex]}
        />
      )}
      {isAchievementsModalOpen && (
        <AchievementsModal
          onClose={handleCloseAchievementsModal}
          onSubmit={handleAchievementsSubmit}
          isUpdateMode={editAchievementsIndex !== null}
          initialFormData={achievements[editAchievementsIndex]}
        />
      )}
      {isCategoryModalOpen && (
        <CategoryModal userId={login?.id} onClose={handleCloseCategoryModal} />
      )}
    </>
  );
};

export default UpdateWorkerProfile;
